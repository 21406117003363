// Indonesia phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_ui_examples: 'Contoh',
  com_ui_new_chat: 'Chat Baru',
  com_ui_happy_birthday: 'Ini ulang tahun pertamaku!',
  com_ui_example_quantum_computing: 'Jelaskan komputasi kuantum dengan istilah yang sederhana',
  com_ui_example_10_year_old_b_day: 'Ada ide kreatif untuk ulang tahun anak 10 tahun?',
  com_ui_example_http_in_js: 'Bagaimana cara membuat permintaan HTTP dalam Javascript?',
  com_ui_capabilities: 'Kemampuan',
  com_ui_capability_remember: 'Mengingat apa yang pengguna katakan sebelumnya dalam percakapan',
  com_ui_capability_correction: 'Memungkinkan pengguna untuk memberikan koreksi tindak lanjut',
  com_ui_capability_decline_requests: 'Dilatih untuk menolak permintaan yang tidak pantas',
  com_ui_limitations: 'Keterbatasan',
  com_ui_limitation_incorrect_info: 'Mungkin sesekali menghasilkan informasi yang salah',
  com_ui_limitation_harmful_biased:
    'Mungkin sesekali menghasilkan instruksi yang berbahaya atau konten yang bias',
  com_ui_limitation_limited_2021: 'Pengetahuan terbatas tentang dunia dan peristiwa setelah 2021',
  com_ui_experimental: 'Fitur Eksperimental',
  com_ui_input: 'Masukan',
  com_ui_close: 'Tutup',
  com_ui_model: 'Model',
  com_ui_select_model: 'Pilih model',
  com_ui_use_prompt: 'Gunakan petunjuk',
  com_ui_prev: 'Sebelumnya',
  com_ui_next: 'Berikutnya',
  com_ui_stop: 'Berhenti',
  com_ui_prompt_templates: 'Template Petunjuk',
  com_ui_hide_prompt_templates: 'Sembunyikan Template Petunjuk',
  com_ui_showing: 'Menampilkan',
  com_ui_of: 'dari',
  com_ui_entries: 'Entri',
  com_ui_pay_per_call:
    'Semua percakapan AI di satu tempat. Bayar per panggilan dan bukan per bulan',
  com_ui_new_footer: 'Semua percakapan AI di satu tempat.',
  com_ui_enter: 'Masuk',
  com_ui_submit: 'Kirim',
  com_ui_upload_success: 'Berhasil mengunggah file',
  com_ui_upload_error: 'Ada kesalahan saat mengunggah file Anda',
  com_ui_cancel: 'Batal',
  com_ui_save: 'Simpan',
  com_ui_save_submit: 'Simpan & Kirim',
  com_user_message: 'Kamu',
  com_ui_copy_to_clipboard: 'Salin ke papan klip',
  com_ui_copied_to_clipboard: 'Disalin ke papan klip',
  com_ui_regenerate: 'Regenerasi',
  com_ui_continue: 'Lanjutkan',
  com_ui_edit: 'Edit',
  com_ui_success: 'Berhasil',
  com_ui_all: 'semua',
  com_ui_clear: 'Bersihkan',
  com_ui_revoke: 'Cabut',
  com_ui_revoke_info: 'Cabut semua kredensial yang diberikan pengguna',
  com_ui_import_conversation: 'Impor',
  com_ui_import_conversation_info: 'Impor percakapan dari file JSON',
  com_ui_import_conversation_success: 'Percakapan berhasil diimpor',
  com_ui_import_conversation_error: 'Terjadi kesalahan saat mengimpor percakapan Anda',
  com_ui_confirm_action: 'Konfirmasi Aksi',
  com_ui_chats: 'chat',
  com_ui_share: 'Bagikan',
  com_ui_copy_link: 'Salin tautan',
  com_ui_update_link: 'Perbarui tautan',
  com_ui_create_link: 'Buat tautan',
  com_ui_share_link_to_chat: 'Bagikan tautan ke chat',
  com_ui_share_error: 'Terjadi kesalahan saat membagikan tautan chat',
  com_ui_share_retrieve_error: 'Terjadi kesalahan saat menghapus tautan yang dibagikan.',
  com_ui_share_delete_error: 'Terjadi kesalahan saat menghapus tautan yang dibagikan.',
  com_ui_share_create_message:
    'Nama Anda dan pesan apa pun yang Anda tambahkan setelah berbagi tetap pribadi.',
  com_ui_share_created_message:
    'Tautan berbagi ke chat Anda telah dibuat. Kelola chat yang pernah dibagikan kapan saja melalui Pengaturan.',
  com_ui_share_update_message:
    'Nama Anda, petunjuk khusus, dan pesan apa pun yang Anda tambahkan setelah berbagi tetap pribadi.',
  com_ui_share_updated_message:
    'Tautan berbagi ke chat Anda telah diperbarui. Kelola chat yang pernah dibagikan kapan saja melalui Pengaturan.',
  com_ui_shared_link_not_found: 'Tautan berbagi tidak ditemukan',
  com_ui_delete: 'Hapus',
  com_ui_delete_conversation: 'Hapus chat?',
  com_ui_delete_confirm: 'Ini akan menghapus',
  com_ui_rename: 'Renombrar',
  com_ui_archive: 'Arsip',
  com_ui_archive_error: 'Gagal mengarsipkan percakapan',
  com_ui_unarchive: 'Buka Arsip',
  com_ui_unarchive_error: 'Gagal membuka arsip',
  com_ui_more_options: 'Lebih',
  com_ui_preview: 'Pratinjau',
  com_ui_upload: 'Unggah',
  com_ui_connect: 'Hubungkan',
  com_ui_bookmarks: 'Penanda',
  com_ui_bookmarks_rebuild: 'Bangun Kembali',
  com_ui_bookmarks_new: 'Penanda Baru',
  com_ui_bookmark_delete_confirm: 'Apakah Anda yakin ingin menghapus penanda ini?',
  com_ui_bookmarks_title: 'Judul',
  com_ui_bookmarks_count: 'Jumlah',
  com_ui_bookmarks_description: 'Deskripsi',
  com_ui_bookmarks_create_success: 'Penanda berhasil dibuat',
  com_ui_bookmarks_update_success: 'Penanda berhasil diperbarui',
  com_ui_bookmarks_delete_success: 'Penanda berhasil dihapus',
  com_ui_bookmarks_create_error: 'Terjadi kesalahan saat membuat penanda',
  com_ui_bookmarks_update_error: 'Terjadi kesalahan saat memperbarui penanda',
  com_ui_bookmarks_delete_error: 'Terjadi kesalahan saat menghapus penanda',
  com_ui_bookmarks_add_to_conversation: 'Tambahkan ke percakapan saat ini',
  com_ui_accept: 'Saya menerima',
  com_ui_decline: 'Saya tidak menerima',
  com_ui_terms_and_conditions: 'Syarat dan Ketentuan',
  com_ui_no_terms_content: 'Tidak ada konten syarat dan ketentuan untuk ditampilkan',
  com_auth_error_login:
    'Tidak dapat masuk dengan informasi yang diberikan. Silakan periksa kredensial Anda dan coba lagi.',
  com_auth_error_login_rl:
    'Terlalu banyak upaya masuk dalam waktu singkat. Silakan coba lagi nanti.',
  com_auth_error_login_ban: 'Akun Anda telah dilarang sementara karena pelanggaran layanan kami.',
  com_auth_error_login_server:
    'Ada kesalahan server internal. Harap tunggu beberapa saat dan coba lagi.',
  com_auth_no_account: 'Tidak memiliki akun?',
  com_auth_sign_up: 'Daftar',
  com_auth_sign_in: 'Masuk',
  com_auth_google_login: 'Masuk dengan Google',
  com_auth_facebook_login: 'Masuk dengan Facebook',
  com_auth_github_login: 'Masuk dengan Github',
  com_auth_discord_login: 'Masuk dengan Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'Email diperlukan',
  com_auth_email_min_length: 'Email harus setidaknya 6 karakter',
  com_auth_email_max_length: 'Email tidak boleh lebih dari 120 karakter',
  com_auth_email_pattern: 'Anda harus memasukkan alamat email yang valid',
  com_auth_email_address: 'Alamat email',
  com_auth_password: 'Kata sandi',
  com_auth_password_required: 'Kata sandi diperlukan',
  com_auth_password_min_length: 'Kata sandi harus setidaknya 8 karakter',
  com_auth_password_max_length: 'Kata sandi harus kurang dari 128 karakter',
  com_auth_password_forgot: 'Lupa Kata Sandi?',
  com_auth_password_confirm: 'Konfirmasi kata sandi',
  com_auth_password_not_match: 'Kata sandi tidak cocok',
  com_auth_continue: 'Lanjutkan',
  com_auth_create_account: 'Buat akun Anda',
  com_auth_error_create: 'Ada kesalahan saat mencoba mendaftarkan akun Anda. Silakan coba lagi.',
  com_auth_full_name: 'Nama lengkap',
  com_auth_name_required: 'Nama diperlukan',
  com_auth_name_min_length: 'Nama harus setidaknya 3 karakter',
  com_auth_name_max_length: 'Nama harus kurang dari 80 karakter',
  com_auth_username: 'Nama pengguna (opsional)',
  com_auth_username_required: 'Nama pengguna diperlukan',
  com_auth_username_min_length: 'Nama pengguna harus setidaknya 2 karakter',
  com_auth_username_max_length: 'Nama pengguna harus kurang dari 20 karakter',
  com_auth_already_have_account: 'Sudah memiliki akun?',
  com_auth_login: 'Masuk',
  com_auth_reset_password: 'Atur ulang kata sandi Anda',
  com_auth_click: 'Klik',
  com_auth_here: 'DI SINI',
  com_auth_to_reset_your_password: 'untuk mengatur ulang kata sandi Anda.',
  com_auth_reset_password_link_sent: 'Email Terkirim',
  com_auth_reset_password_email_sent:
    'Email telah dikirim kepada Anda dengan instruksi lebih lanjut untuk mengatur ulang kata sandi Anda.',
  com_auth_error_reset_password:
    'Ada masalah saat mengatur ulang kata sandi Anda. Tidak ada pengguna yang ditemukan dengan alamat email yang diberikan. Silakan coba lagi.',
  com_auth_reset_password_success: 'Berhasil Mengatur Ulang Kata Sandi',
  com_auth_login_with_new_password: 'Anda sekarang dapat masuk dengan kata sandi baru Anda.',
  com_auth_error_invalid_reset_token: 'Token pengaturan ulang kata sandi ini tidak lagi valid.',
  com_auth_click_here: 'Klik di sini',
  com_auth_to_try_again: 'untuk mencoba lagi.',
  com_auth_submit_registration: 'Kirim pendaftaran',
  com_auth_welcome_back: 'Selamat datang kembali',
  com_endpoint_open_menu: 'Buka Menu',
  com_endpoint_bing_enable_sydney: 'Aktifkan Sydney',
  com_endpoint_bing_to_enable_sydney: 'Untuk mengaktifkan Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing dapat menggunakan hingga 7k token untuk \'konteks\', yang dapat dirujuk untuk percakapan. Batas spesifik tidak diketahui tetapi mungkin menemui kesalahan melebihi 7k token',
  com_endpoint_bing_system_message_placeholder:
    'PERINGATAN: Penyalahgunaan fitur ini dapat membuat Anda DILARANG menggunakan Bing! Klik pada \'Pesan Sistem\' untuk instruksi lengkap dan pesan default jika diabaikan, yang merupakan preset \'Sydney\' yang dianggap aman.',
  com_endpoint_system_message: 'Pesan Sistem',
  com_endpoint_message: 'Pesan',
  com_endpoint_message_not_appendable: 'Edit pesan Anda atau Regenerasi.',
  com_endpoint_default_blank: 'default: kosong',
  com_endpoint_default_false: 'default: salah',
  com_endpoint_default_creative: 'default: kreatif',
  com_endpoint_default_empty: 'default: kosong',
  com_endpoint_default_with_num: 'default: {0}',
  com_endpoint_context: 'Konteks',
  com_endpoint_tone_style: 'Gaya Nada',
  com_endpoint_token_count: 'Jumlah Token',
  com_endpoint_output: 'Output',
  com_endpoint_google_temp:
    'Nilai yang lebih tinggi = lebih acak, sedangkan nilai yang lebih rendah = lebih fokus dan deterministik. Kami merekomendasikan untuk mengubah ini atau Top P tetapi tidak keduanya.',
  com_endpoint_google_topp:
    'Top-p mengubah cara model memilih token untuk output. Token dipilih dari yang paling mungkin (lihat parameter topK) hingga yang paling tidak mungkin sampai jumlah probabilitas mereka sama dengan nilai top-p.',
  com_endpoint_google_topk:
    'Top-k mengubah cara model memilih token untuk output. Top-k 1 berarti token yang dipilih adalah yang paling mungkin di antara semua token dalam kosakata model (juga disebut decoding serakah), sedangkan top-k 3 berarti token berikutnya dipilih dari antara 3 token yang paling mungkin (menggunakan temperatur).',
  com_endpoint_google_maxoutputtokens:
    'Jumlah maksimum token yang dapat dihasilkan dalam respons. Tentukan nilai yang lebih rendah untuk respons yang lebih pendek dan nilai yang lebih tinggi untuk respons yang lebih panjang.',
  com_endpoint_google_custom_name_placeholder: 'Tetapkan nama kustom untuk Google',
  com_endpoint_prompt_prefix_placeholder:
    'Tetapkan instruksi kustom atau konteks. Diabaikan jika kosong.',
  com_endpoint_custom_name: 'Nama Kustom',
  com_endpoint_prompt_prefix: 'Awalan Prompt',
  com_endpoint_temperature: 'Temperatur',
  com_endpoint_default: 'default',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Token Output Maks',
  com_endpoint_openai_temp:
    'Nilai yang lebih tinggi = lebih acak, sedangkan nilai yang lebih rendah = lebih fokus dan deterministik. Kami merekomendasikan untuk mengubah ini atau Top P tetapi tidak keduanya.',
  com_endpoint_openai_max:
    'Token maksimum yang akan dihasilkan. Panjang total token masukan dan token yang dihasilkan dibatasi oleh panjang konteks model.',
  com_endpoint_openai_topp:
    'Sebuah alternatif untuk pengambilan sampel dengan suhu, disebut pengambilan sampel inti, di mana model mempertimbangkan hasil dari token dengan massa probabilitas top_p. Jadi 0,1 berarti hanya token yang mencakup 10% massa probabilitas teratas yang dipertimbangkan. Kami merekomendasikan untuk mengubah ini atau suhu tetapi tidak keduanya.',
  com_endpoint_openai_freq:
    'Angka antara -2,0 dan 2,0. Nilai positif menghukum token baru berdasarkan frekuensi mereka yang ada dalam teks sejauh ini, mengurangi kemungkinan model untuk mengulangi baris yang sama secara harfiah.',
  com_endpoint_openai_pres:
    'Angka antara -2,0 dan 2,0. Nilai positif menghukum token baru berdasarkan apakah mereka muncul dalam teks sejauh ini, meningkatkan kemungkinan model untuk berbicara tentang topik baru.',
  com_endpoint_openai_resend:
    'Kirim ulang semua gambar yang sebelumnya dilampirkan. Catatan: ini dapat meningkatkan biaya token secara signifikan dan Anda mungkin mengalami kesalahan dengan banyak lampiran gambar.',
  com_endpoint_openai_detail:
    'Resolusi untuk permintaan Vision. "Rendah" lebih murah dan lebih cepat, "Tinggi" lebih detail dan mahal, dan "Otomatis" akan secara otomatis memilih antara keduanya berdasarkan resolusi gambar.',
  com_endpoint_openai_custom_name_placeholder: 'Tetapkan nama kustom untuk ChatGPT',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Tetapkan instruksi kustom untuk dimasukkan dalam Pesan Sistem. Default: tidak ada',
  com_endpoint_anthropic_temp:
    'Berkisar dari 0 hingga 1. Gunakan temp yang lebih dekat ke 0 untuk analitis / pilihan ganda, dan lebih dekat ke 1 untuk tugas kreatif dan generatif. Kami merekomendasikan untuk mengubah ini atau Top P tetapi tidak keduanya.',
  com_endpoint_anthropic_topp:
    'Top-p mengubah cara model memilih token untuk output. Token dipilih dari yang paling mungkin (lihat parameter topK) hingga yang paling tidak mungkin sampai jumlah probabilitas mereka sama dengan nilai top-p.',
  com_endpoint_anthropic_topk:
    'Top-k mengubah cara model memilih token untuk output. Top-k 1 berarti token yang dipilih adalah yang paling mungkin di antara semua token dalam kosakata model (juga disebut decoding serakah), sedangkan top-k 3 berarti token berikutnya dipilih dari antara 3 token yang paling mungkin (menggunakan suhu).',
  com_endpoint_anthropic_maxoutputtokens:
    'Jumlah maksimum token yang dapat dihasilkan dalam respons. Tentukan nilai yang lebih rendah untuk respons yang lebih pendek dan nilai yang lebih tinggi untuk respons yang lebih panjang.',
  com_endpoint_anthropic_custom_name_placeholder: 'Tetapkan nama kustom untuk Anthropic',
  com_endpoint_frequency_penalty: 'Penalti Frekuensi',
  com_endpoint_presence_penalty: 'Penalti Kehadiran',
  com_endpoint_plug_use_functions: 'Gunakan Fungsi',
  com_endpoint_plug_resend_images: 'Kirim Ulang Gambar',
  com_endpoint_plug_image_detail: 'Rincian Gambar',
  com_endpoint_plug_skip_completion: 'Lewati Penyelesaian',
  com_endpoint_disabled_with_tools: 'dinonaktifkan dengan alat',
  com_endpoint_disabled_with_tools_placeholder: 'Dinonaktifkan dengan Alat yang Dipilih',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Tetapkan instruksi kustom untuk dimasukkan dalam Pesan Sistem. Default: tidak ada',
  com_endpoint_import: 'Impor',
  com_endpoint_set_custom_name: 'Tetapkan nama kustom, jika Anda dapat menemukan preset ini',
  com_endpoint_preset_delete_confirm: 'Anda yakin ingin menghapus preset ini?',
  com_endpoint_preset_clear_all_confirm: 'Anda yakin ingin menghapus semua preset Anda?',
  com_endpoint_preset_import: 'Preset Diimpor!',
  com_endpoint_preset_import_error: 'Ada kesalahan saat mengimpor preset Anda. Silakan coba lagi.',
  com_endpoint_preset_save_error: 'Ada kesalahan saat menyimpan preset Anda. Silakan coba lagi.',
  com_endpoint_preset_delete_error: 'Ada kesalahan saat menghapus preset Anda. Silakan coba lagi.',
  com_endpoint_preset_default_removed: 'tidak lagi menjadi preset default.',
  com_endpoint_preset_default_item: 'Default:',
  com_endpoint_preset_default_none: 'Tidak ada preset default yang aktif.',
  com_endpoint_preset_title: 'Preset',
  com_ui_saved: 'Tersimpan!',
  com_endpoint_preset_default: 'sekarang menjadi preset default.',
  com_endpoint_preset: 'preset',
  com_endpoint_presets: 'presets',
  com_endpoint_preset_selected: 'Preset Aktif!',
  com_endpoint_preset_selected_title: 'Aktif!',
  com_endpoint_preset_name: 'Nama Preset',
  com_endpoint_new_topic: 'Topik Baru',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Sembunyikan',
  com_endpoint_show: 'Tampilkan',
  com_endpoint_examples: ' Preset',
  com_endpoint_completion: 'Penyelesaian',
  com_endpoint_agent: 'Agen',
  com_endpoint_show_what_settings: 'Tampilkan {0} Pengaturan',
  com_endpoint_save: 'Simpan',
  com_endpoint_export: 'Ekspor',
  com_endpoint_save_as_preset: 'Simpan Sebagai Preset',
  com_endpoint_presets_clear_warning:
    'Anda yakin ingin menghapus semua preset? Ini tidak dapat dibatalkan.',
  com_endpoint_not_implemented: 'Belum diimplementasikan',
  com_endpoint_no_presets: 'Belum ada preset, gunakan tombol pengaturan untuk membuat satu',
  com_endpoint_not_available: 'Endpoint tidak tersedia',
  com_endpoint_view_options: 'Lihat Opsi',
  com_endpoint_save_convo_as_preset: 'Simpan Percakapan sebagai Preset',
  com_endpoint_my_preset: 'Preset Saya',
  com_endpoint_agent_model: 'Model Agen (Direkomendasikan: GPT-3.5)',
  com_endpoint_completion_model: 'Model Penyelesaian (Direkomendasikan: GPT-4)',
  com_endpoint_func_hover: 'Aktifkan penggunaan Plugin sebagai Fungsi OpenAI',
  com_endpoint_skip_hover:
    'Aktifkan langkah penyelesaian yang dilewati, yang meninjau jawaban akhir dan langkah yang dihasilkan',
  com_endpoint_config_key: 'Atur Kunci API',
  com_endpoint_config_placeholder: 'Atur Kunci Anda di menu Header untuk mengobrol.',
  com_endpoint_config_key_for: 'Atur Kunci API untuk',
  com_endpoint_config_key_name: 'Kunci',
  com_endpoint_config_value: 'Masukkan nilai untuk',
  com_endpoint_config_key_name_placeholder: 'Atur kunci API terlebih dahulu',
  com_endpoint_config_key_encryption: 'Kunci Anda akan dienkripsi dan dihapus pada',
  com_endpoint_config_key_expiry: 'waktu kedaluwarsa',
  com_endpoint_config_click_here: 'Klik Di Sini',
  com_endpoint_config_google_service_key: 'Kunci Akun Layanan Google',
  com_endpoint_config_google_cloud_platform: '(dari Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Kunci API Google',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info:
    'Untuk mendapatkan kunci API Bahasa Generatif Anda (untuk Gemini),',
  com_endpoint_config_key_import_json_key: 'Impor Kunci JSON Akun Layanan.',
  com_endpoint_config_key_import_json_key_success: 'Berhasil Mengimpor Kunci JSON Akun Layanan',
  com_endpoint_config_key_import_json_key_invalid:
    'Kunci JSON Akun Layanan Tidak Valid, Apakah Anda mengimpor file yang benar?',
  com_endpoint_config_key_get_edge_key: 'Untuk mendapatkan token akses Anda untuk Bing, masuk ke',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Gunakan alat pengembang atau ekstensi saat masuk ke situs untuk menyalin konten dari cookie _U. Jika ini gagal, ikuti ini',
  com_endpoint_config_key_edge_instructions: 'instruksi',
  com_endpoint_config_key_edge_full_key_string: 'untuk memberikan string cookie lengkap.',
  com_endpoint_config_key_chatgpt:
    'Untuk mendapatkan token akses Anda Untuk ChatGPT \'Versi Gratis\', masuk ke',
  com_endpoint_config_key_chatgpt_then_visit: 'kemudian kunjungi',
  com_endpoint_config_key_chatgpt_copy_token: 'Salin token akses.',
  com_endpoint_config_key_google_need_to: 'Anda perlu',
  com_endpoint_config_key_google_vertex_ai: 'Aktifkan Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API di Google Cloud, kemudian',
  com_endpoint_config_key_google_service_account: 'Buat Akun Layanan',
  com_endpoint_config_key_google_vertex_api_role:
    'Pastikan untuk mengklik \'Buat dan Lanjutkan\' untuk memberikan setidaknya peran \'Pengguna Vertex AI\'. Terakhir, buat kunci JSON untuk diimpor di sini.',
  com_nav_welcome_message: 'Bagaimana saya bisa membantu Anda hari ini?',
  com_nav_auto_scroll: 'Otomatis gulir ke Baru saat Buka',
  com_nav_modular_chat: 'Aktifkan penggantian Endpoint di tengah percakapan',
  com_nav_latex_parsing: 'Parsing LaTeX dalam pesan (dapat memengaruhi kinerja)',
  com_nav_profile_picture: 'Foto Profil',
  com_nav_change_picture: 'Ubah foto',
  com_nav_plugin_store: 'Toko plugin',
  com_show_agent_settings: 'Tampilkan Pengaturan Agen',
  com_show_completion_settings: 'Tampilkan Pengaturan Penyelesaian',
  com_hide_examples: 'Sembunyikan Contoh',
  com_show_examples: 'Tampilkan Contoh',
  com_nav_plugin_search: 'Cari plugin',
  com_nav_plugin_auth_error:
    'Ada kesalahan saat mencoba mengautentikasi plugin ini. Silakan coba lagi.',
  com_nav_export_filename: 'Nama File',
  com_nav_export_filename_placeholder: 'Atur nama file',
  com_nav_export_type: 'Tipe',
  com_nav_export_include_endpoint_options: 'Sertakan opsi endpoint',
  com_nav_enabled: 'Diaktifkan',
  com_nav_not_supported: 'Tidak Didukung',
  com_nav_export_all_message_branches: 'Ekspor semua cabang pesan',
  com_nav_export_recursive_or_sequential: 'Rekursif atau berurutan?',
  com_nav_export_recursive: 'Rekursif',
  com_nav_export_conversation: 'Ekspor percakapan',
  com_nav_export: 'Ekspor',
  com_nav_shared_links: 'Link berbagi',
  com_nav_shared_links_manage: 'Pengeluaran',
  com_nav_shared_links_empty: 'Anda tidak memiliki link berbagi.',
  com_nav_shared_links_name: 'Nama',
  com_nav_shared_links_date_shared: 'Tanggal berbagi',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistem',
  com_nav_theme_dark: 'Gelap',
  com_nav_theme_light: 'Terang',
  com_nav_font_size: 'Ukuran huruf',
  com_nav_user_name_display: 'Tampilkan nama pengguna dalam pesan',
  com_nav_save_drafts: 'Simpan draft',
  com_nav_clear_all_chats: 'Hapus semua obrolan',
  com_nav_confirm_clear: 'Konfirmasi Hapus',
  com_nav_close_sidebar: 'Tutup sidebar',
  com_nav_open_sidebar: 'Buka sidebar',
  com_nav_send_message: 'Kirim pesan',
  com_nav_log_out: 'Keluar',
  com_nav_user: 'PENGGUNA',
  com_nav_archived_chats: 'Percakapan Arsip',
  com_nav_archived_chats_manage: 'Pengelolaan',
  com_nav_archived_chats_empty: 'Tidak ada percakapan yang diarsipkan.',
  com_nav_archive_all_chats: 'Arsipkan semua percakapan',
  com_nav_archive_all: 'Arsipkan semua',
  com_nav_archive_name: 'Nama',
  com_nav_archive_created_at: 'TanggalDibuat',
  com_nav_clear_conversation: 'Hapus percakapan',
  com_nav_clear_conversation_confirm_message:
    'Anda yakin ingin menghapus semua percakapan? Ini tidak dapat dibatalkan.',
  com_nav_help_faq: 'Bantuan & FAQ',
  com_nav_settings: 'Pengaturan',
  com_nav_search_placeholder: 'Cari pesan',
  com_nav_info_bookmarks_rebuild:
    'Jika jumlah penanda tidak benar, silakan bangun kembali informasi penanda. Jumlah penanda akan dihitung ulang dan data akan dipulihkan ke keadaan yang benar.',
  com_nav_setting_general: 'Umum',
  com_nav_setting_beta: 'Fitur beta',
  com_nav_setting_data: 'Kontrol data',
  com_nav_setting_account: 'Akun',
  com_nav_language: 'Bahasa',
  com_nav_lang_auto: 'Deteksi otomatis',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
};
