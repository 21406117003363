import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys, Constants } from 'librechat-data-provider';
import type { TMessage } from 'librechat-data-provider';
import { useMediaQuery, useLocalize } from '~/hooks';
import { NewChatIcon } from '~/components/svg';
import { useChatContext } from '~/Providers';

export default function HeaderNewChat() {
  const queryClient = useQueryClient();
  const { conversation, newConversation } = useChatContext();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const localize = useLocalize();
  if (isSmallScreen) {
    return null;
  }
  return (
    <button
      data-testid="wide-header-new-chat-button"
      aria-label={localize('com_ui_new_chat')}
      type="button"
      className="btn-small relative ml-2 flex hidden h-9 w-9 items-center justify-center whitespace-nowrap rounded-lg border border-[#9A9A9A] hover:bg-[#F2F2F2] focus:ring-0 focus:ring-offset-0 md:flex dark:border-[#435880] dark:hover:bg-[#1c2846]"
      onClick={() => newConversation()}
    >
      <div className="flex w-full items-center justify-center gap-2 text-[#0F172A] hover:text-black dark:text-white dark:hover:text-white">
        <NewChatIcon />
      </div>
    </button>
  );
}
