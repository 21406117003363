import React from 'react';
import { useLocalize } from '~/hooks';
import { BlinkAnimation } from './BlinkAnimation';
import { TStartupConfig } from 'librechat-data-provider';
import SocialLoginRender from './SocialLoginRender';
import { ThemeSelector } from '~/components/ui';
import { Banner } from '../Banners';
import Footer from './Footer';
import AILogoDark from '/assets/AILogoDark.svg';
import AILogoLight from '/assets/AILogoLight.svg';
import BackgroundLayout from '../Layout/BackgroundLayout';

const ErrorRender = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-16 flex max-w-[658px] justify-center">
    <div
      role="alert"
      aria-live="assertive"
      className="rounded-md border border-red-500 bg-red-500/10 px-3 py-2 text-sm text-gray-600 dark:text-gray-200"
    >
      {children}
    </div>
  </div>
);

function AuthLayout({
  children,
  header,
  isFetching,
  startupConfig,
  startupConfigError,
  pathname,
  error,
}: {
  children: React.ReactNode;
  header: React.ReactNode;
  isFetching: boolean;
  startupConfig: TStartupConfig | null | undefined;
  startupConfigError: unknown | null | undefined;
  pathname: string;
  error: string | null;
}) {
  const localize = useLocalize();

  const hasStartupConfigError = startupConfigError !== null && startupConfigError !== undefined;
  const DisplayError = () => {
    if (hasStartupConfigError) {
      return <ErrorRender>{localize('com_auth_error_login_server')}</ErrorRender>;
    } else if (error === 'com_auth_error_invalid_reset_token') {
      return (
        <ErrorRender>
          {localize('com_auth_error_invalid_reset_token')}{' '}
          <a
            className="font-semibold text-green-600 hover:underline"
            href="/forgot-password"
          >
            {localize('com_auth_click_here')}
          </a>{' '}
          {localize('com_auth_to_try_again')}
        </ErrorRender>
      );
    } else if (error != null && error) {
      return <ErrorRender>{localize(error)}</ErrorRender>;
    }
    return null;
  };

  const hideLinks =
    pathname.endsWith('/privacy-policy') ||
    pathname.endsWith('/terms-of-service');

  return (
    <div>
      <Banner />
      <BackgroundLayout>
        <BlinkAnimation active={isFetching}>
          <div className="flex flex-col items-center justify-center">
            <div className="z-10 mb-8 w-full bg-cover py-4">
              <img
                src={AILogoLight}
                className="block w-full object-contain dark:hidden"
                alt="Ayzenberg Intelligence"
              />

              <img
                src={AILogoDark}
                className="hidden w-full object-contain dark:block"
                alt="Ayzenberg Intelligence"
              />
            </div>
          </div>
        </BlinkAnimation>
        <DisplayError />
        <div className="absolute bottom-0 left-0 z-10 md:m-4">
          <ThemeSelector />
        </div>

        {!startupConfigError && !isFetching && (
          <>
            {pathname.includes('login') && (
              <p className="z-10 mb-16 mt-8 max-w-[658px] px-8 text-center text-base text-[#0F172A] md:px-32 lg:px-0 dark:text-white">
                Introducing ayzenberg.ai Dashboard, our AI portal, designed to
                serve as your personal [a]marketing consultant and advanced
                research assistant.
              </p>
            )}
          </>
        )}
        <div className="z-10">
          <div className="flex items-center justify-center">
            <div className="max-w-[370px] overflow-hidden md:max-w-[800px] lg:max-w-[1184px]">
              {!startupConfigError && !isFetching && (
                <h1
                  className="gradient-text mb-4 text-center text-3xl font-light "
                  style={{
                    userSelect: 'none',
                  }}
                >
                  {header}
                </h1>
              )}
              {children}
              {(pathname.includes('login') ||
                pathname.includes('register')) && (
                <SocialLoginRender startupConfig={startupConfig} />
              )}
            </div>
          </div>
          <Footer startupConfig={startupConfig} hideLinks={hideLinks} />
        </div>
      </BackgroundLayout>
    </div>
  );
}

export default AuthLayout;
