// Italian phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_error_moderation:
    'Sembra che il contenuto inviato sia stato contrassegnato dal nostro sistema di moderazione per non essere allineato con le nostre linee guida della community. Non possiamo procedere con questo argomento specifico. Se hai altre domande o argomenti che vorresti esplorare, modifica il tuo messaggio o crea una nuova conversazione.',
  com_error_no_user_key: 'Nessuna chiave trovata. Fornisci una chiave e riprova.',
  com_error_no_base_url: 'Nessun URL base trovato. Forniscine uno e riprova.',
  com_error_invalid_user_key: 'Chiave fornita non valida. Fornisci una chiave e riprova.',
  com_error_expired_user_key:
    'La chiave fornita per {0} è scaduta il {1}. Fornisci una chiave e riprova.',
  com_files_no_results: 'Nessun risultato.',
  com_files_filter: 'Filtra file...',
  com_files_number_selected: '{0} di {1} file selezionati',
  com_sidepanel_select_assistant: 'Seleziona un Assistente',
  com_sidepanel_parameters: 'Parametri',
  com_sidepanel_assistant_builder: 'Costruttore Assistente',
  com_sidepanel_hide_panel: 'Nascondi Pannello',
  com_sidepanel_attach_files: 'Allega File',
  com_sidepanel_manage_files: 'Gestisci File',
  com_sidepanel_conversation_tags: 'Segnalibri',
  com_assistants_capabilities: 'Capacità',
  com_assistants_knowledge: 'Conoscenza',
  com_assistants_knowledge_info:
    'Se carichi file sotto Conoscenza, le conversazioni con il tuo Assistente potrebbero includere i contenuti dei file.',
  com_assistants_knowledge_disabled:
    'L\'Assistente deve essere creato, e Code Interpreter o Retrieval devono essere abilitati e salvati prima di caricare file come Conoscenza.',
  com_assistants_image_vision: 'Visione Immagine',
  com_assistants_append_date: 'Aggiungi Data e Ora Attuali',
  com_assistants_append_date_tooltip:
    'Quando attivo, la data e l\'ora attuali del cliente saranno aggiunte alle istruzioni del sistema dell\'Assistente.',
  com_assistants_code_interpreter: 'Interprete Codice',
  com_assistants_code_interpreter_files:
    'I seguenti file sono disponibili solo per Code Interpreter:',
  com_assistants_retrieval: 'Retrival',
  com_assistants_search_name: 'Cerca assistenti per nome',
  com_ui_tools: 'Strumenti',
  com_assistants_actions: 'Azioni',
  com_assistants_add_tools: 'Aggiungi Strumenti',
  com_assistants_add_actions: 'Aggiungi Azioni',
  com_assistants_available_actions: 'Azioni Disponibili',
  com_assistants_running_action: 'Azione in corso',
  com_assistants_completed_action: 'Parlato con {0}',
  com_assistants_completed_function: 'Eseguito {0}',
  com_assistants_function_use: 'L\'Assistente ha usato {0}',
  com_assistants_domain_info: 'L\'Assistente ha inviato queste informazioni a {0}',
  com_assistants_delete_actions_success: 'Azione eliminata dall\'Assistente con successo',
  com_assistants_update_actions_success: 'Azione creata o aggiornata con successo',
  com_assistants_update_actions_error:
    'Si è verificato un errore durante la creazione o l\'aggiornamento dell\'azione.',
  com_assistants_delete_actions_error:
    'Si è verificato un errore durante l\'eliminazione dell\'azione.',
  com_assistants_actions_info:
    'Permetti al tuo Assistente di recuperare informazioni o eseguire azioni tramite API',
  com_assistants_name_placeholder: 'Opzionale: Il nome dell\'assistente',
  com_assistants_instructions_placeholder: 'Le istruzioni di sistema che l\'assistente utilizza',
  com_assistants_description_placeholder: 'Opzionale: Descrivi qui il tuo Assistente',
  com_assistants_actions_disabled: 'Devi prima creare un assistente prima di aggiungere azioni.',
  com_assistants_update_success: 'Aggiornamento avvenuto con successo',
  com_assistants_update_error:
    'Si è verificato un errore durante l\'aggiornamento del tuo assistente.',
  com_assistants_create_success: 'Creazione avvenuta con successo',
  com_assistants_create_error: 'Si è verificato un errore durante la creazione del tuo assistente.',
  com_ui_field_required: 'Questo campo è obbligatorio',
  com_ui_download_error:
    'Errore durante il download del file. Il file potrebbe essere stato eliminato.',
  com_ui_attach_error_type: 'Tipo di file non supportato per l\'endpoint:',
  com_ui_attach_error_size: 'Limite dimensione file superato per l\'endpoint:',
  com_ui_attach_error:
    'Impossibile allegare il file. Crea o seleziona una conversazione, oppure prova a ricaricare la pagina.',
  com_ui_examples: 'Esempi',
  com_ui_new_chat: 'Nuova chat',
  com_ui_happy_birthday: 'È il mio 1° compleanno!',
  com_ui_example_quantum_computing: 'Spiega l\'informatica quantistica in termini semplici',
  com_ui_example_10_year_old_b_day:
    'Hai qualche idea creativa per il compleanno di un bambino di 10 anni?',
  com_ui_example_http_in_js: 'Come faccio a fare una richiesta HTTP in Javascript?',
  com_ui_capabilities: 'Capacità',
  com_ui_capability_remember: 'Ricorda ciò che l\'utente ha detto prima nella conversazione',
  com_ui_capability_correction: 'Permette all\'utente di fornire correzioni successive',
  com_ui_capability_decline_requests: 'Addestrato a rifiutare richieste inappropriate',
  com_ui_limitations: 'Limitazioni',
  com_ui_limitation_incorrect_info: 'Potrebbe occasionalmente generare informazioni non corrette',
  com_ui_limitation_harmful_biased:
    'Potrebbe occasionalmente produrre istruzioni dannose o contenuti di parte',
  com_ui_limitation_limited_2021: 'Conoscenza limitata del mondo e degli eventi dopo il 2021',
  com_ui_experimental: 'Funzionalità Sperimentali',
  com_ui_on: 'Attivo',
  com_ui_off: 'Disattivo',
  com_ui_yes: 'Sì',
  com_ui_no: 'No',
  com_ui_ascending: 'Crescente',
  com_ui_descending: 'Decrescente',
  com_ui_show_all: 'Mostra Tutto',
  com_ui_name: 'Nome',
  com_ui_date: 'Data',
  com_ui_storage: 'Archiviazione',
  com_ui_context: 'Contesto',
  com_ui_size: 'Dimensione',
  com_ui_host: 'Host',
  com_ui_update: 'Aggiorna',
  com_ui_authentication: 'Autenticazione',
  com_ui_instructions: 'Istruzioni',
  com_ui_description: 'Descrizione',
  com_ui_error: 'Errore',
  com_ui_select: 'Seleziona',
  com_ui_input: 'Input',
  com_ui_close: 'Chiudi',
  com_ui_model: 'Modello',
  com_ui_select_model: 'Seleziona un modello',
  com_ui_select_search_model: 'Cerca modello per nome',
  com_ui_select_search_plugin: 'Cerca plugin per nome',
  com_ui_use_prompt: 'Usa prompt',
  com_ui_prev: 'Prec',
  com_ui_next: 'Succ',
  com_ui_stop: 'Ferma',
  com_ui_upload_files: 'Carica file',
  com_ui_prompt_templates: 'Modelli di prompt',
  com_ui_hide_prompt_templates: 'Nascondi modelli di prompt',
  com_ui_showing: 'Mostrando',
  com_ui_of: 'di',
  com_ui_entries: 'Voci',
  com_ui_pay_per_call: 'Tutte le conversazioni AI in un unico posto. Paga a chiamata e non a mese',
  com_ui_new_footer: 'Tutte le conversazioni AI in un unico posto.',
  com_ui_enter: 'Invio',
  com_ui_submit: 'Invia',
  com_ui_none_selected: 'Nessuna selezionata',
  com_ui_upload_success: 'File caricato con successo',
  com_ui_upload_error: 'Si è verificato un errore durante il caricamento del file',
  com_ui_cancel: 'Annulla',
  com_ui_save: 'Salva',
  com_ui_save_submit: 'Salva e Invia',
  com_ui_copy_to_clipboard: 'Copia negli appunti',
  com_ui_copied_to_clipboard: 'Copiato negli appunti',
  com_ui_fork_info_1:
    'Usa questa impostazione per duplicare i messaggi con il comportamento desiderato.',
  com_ui_fork_info_2:
    '"Duplicare" si riferisce alla creazione di una nuova conversazione che inizia/termina dai messaggi specifici nella conversazione corrente, creando una copia in base alle opzioni selezionate.',
  com_ui_fork_info_3:
    'Il "messaggio di destinazione" si riferisce al messaggio dal quale è stato aperto questo popup, oppure, se selezioni "{0}", all\'ultimo messaggio della conversazione.',
  com_ui_fork_info_visible:
    'Questa opzione duplica solo i messaggi visibili; in altre parole, il percorso diretto al messaggio di destinazione, senza alcun ramo.',
  com_ui_fork_info_branches:
    'Questa opzione duplica i messaggi visibili, insieme ai rami correlati; in altre parole, il percorso diretto al messaggio di destinazione, inclusi i rami lungo il percorso.',
  com_ui_fork_info_target:
    'Questa opzione duplica tutti i messaggi che portano al messaggio di destinazione, inclusi i suoi vicini; in altre parole, sono inclusi tutti i rami di messaggi, sia che siano visibili o meno o lungo lo stesso percorso.',
  com_ui_fork_info_start:
    'Se selezionato, la duplicazione partirà da questo messaggio fino all\'ultimo messaggio della conversazione, in base al comportamento selezionato sopra.',
  com_ui_fork_info_remember:
    'Seleziona questa opzione per ricordare le opzioni selezionate per un futuro utilizzo, rendendo più veloce la duplicazione delle conversazioni come preferito.',
  com_ui_fork_success: 'Conversazione duplicata con successo',
  com_ui_fork_processing: 'Duplicazione conversazione in corso...',
  com_ui_fork_error: 'Si è verificato un errore durante la duplicazione della conversazione',
  com_ui_fork_change_default: 'Cambia opzione di duplicazione predefinita',
  com_ui_fork_default: 'Usa opzione di duplicazione predefinita',
  com_ui_fork_remember: 'Ricorda',
  com_ui_fork_split_target_setting:
    'Avvia la duplicazione dal messaggio di destinazione per impostazione predefinita',
  com_ui_fork_split_target: 'Avvia la duplicazione da qui',
  com_ui_fork_remember_checked:
    'La tua selezione verrà ricordata dopo l\'utilizzo. Puoi cambiarla in qualsiasi momento nelle impostazioni.',
  com_ui_fork_all_target: 'Includi tutto da/per qui',
  com_ui_fork_branches: 'Includi rami correlati',
  com_ui_fork_visible: 'Solo messaggi visibili',
  com_ui_fork_from_message: 'Seleziona un\'opzione di duplicazione',
  com_ui_regenerate: 'Rigenera',
  com_ui_continue: 'Continua',
  com_ui_edit: 'Modifica',
  com_ui_success: 'Successo',
  com_ui_all: 'tutto',
  com_ui_clear: 'Cancella',
  com_ui_revoke: 'Revoca',
  com_ui_revoke_info: 'Revoca tutte le credenziali fornite dall\'utente',
  com_ui_import_conversation: 'Importa',
  com_ui_import_conversation_info: 'Importa conversazioni da un file JSON',
  com_ui_import_conversation_success: 'Conversazioni importate con successo',
  com_ui_import_conversation_error:
    'Si è verificato un errore durante l\'importazione delle conversazioni',
  com_ui_import_conversation_file_type_error: 'Tipo di importazione non supportato',
  com_ui_confirm_action: 'Conferma Azione',
  com_ui_chats: 'chat',
  com_ui_avatar: 'Avatar',
  com_ui_unknown: 'Sconosciuto',
  com_ui_result: 'Risultato',
  com_ui_image_gen: 'Generazione immagine',
  com_ui_assistant: 'Assistente',
  com_ui_assistants: 'Assistenti',
  com_ui_attachment: 'Allegato',
  com_ui_assistants_output: 'Output Assistenti',
  com_ui_delete: 'Elimina',
  com_ui_create: 'Crea',
  com_ui_share: 'Condividi',
  com_ui_copy_link: 'Copia link',
  com_ui_update_link: 'Aggiorna link',
  com_ui_create_link: 'Crea link',
  com_ui_share_link_to_chat: 'Condividi link a chat',
  com_ui_share_error: 'Si è verificato un errore durante la condivisione del link della chat',
  com_ui_share_retrieve_error:
    'Si è verificato un errore durante l\'eliminazione del link condiviso.',
  com_ui_share_delete_error: 'Si è verificato un errore durante l\'eliminazione del link condiviso.',
  com_ui_share_create_message:
    'Il tuo nome e qualsiasi messaggio aggiunto dopo la condivisione rimarranno privati.',
  com_ui_share_created_message:
    'È stato creato un link condiviso per la tua chat. Gestisci le chat condivise in precedenza in qualsiasi momento tramite Impostazioni.',
  com_ui_share_update_message:
    'Il tuo nome, istruzioni personalizzate e qualsiasi messaggio aggiunto dopo la condivisione rimarranno privati.',
  com_ui_share_updated_message:
    'Il link condiviso per la tua chat è stato aggiornato. Gestisci le chat condivise in precedenza in qualsiasi momento tramite Impostazioni.',
  com_ui_shared_link_not_found: 'Link condiviso non trovato',
  com_ui_delete_conversation: 'Eliminare la chat?',
  com_ui_delete_confirm: 'Questo eliminerà',
  com_ui_rename: 'Rinomina',
  com_ui_archive: 'Archivia',
  com_ui_archive_error: 'Errore durante l\'archiviazione della conversazione',
  com_ui_unarchive: 'Disarchivia',
  com_ui_unarchive_error: 'Impossibile disarchiviare la conversazione',
  com_ui_more_options: 'Più',
  com_ui_delete_assistant_confirm:
    'Sei sicuro di voler eliminare questo Assistente? Questa operazione non può essere annullata.',
  com_ui_preview: 'Anteprima',
  com_ui_upload: 'Carica',
  com_ui_connect: 'Connetti',
  com_ui_locked: 'Bloccato',
  com_ui_upload_delay:
    'Il caricamento di "{0}" sta richiedendo più tempo del previsto. Attendi il completamento dell\'indicizzazione per il recupero.',
  com_ui_privacy_policy: 'Informativa sulla privacy',
  com_ui_terms_of_service: 'Termini di servizio',
  com_ui_min_tags: 'Impossibile rimuovere altri valori, è richiesto un minimo di {0}.',
  com_ui_max_tags: 'Il numero massimo consentito è {0}, verranno utilizzati gli ultimi valori.',
  com_ui_bookmarks: 'Segnalibri',
  com_ui_bookmarks_rebuild: 'Ricostruisci',
  com_ui_bookmarks_new: 'Nuovo Segnalibro',
  com_ui_bookmark_delete_confirm: 'Sei sicuro di voler eliminare questo segnalibro?',
  com_ui_bookmarks_title: 'Titolo',
  com_ui_bookmarks_count: 'Conteggio',
  com_ui_bookmarks_description: 'Descrizione',
  com_ui_bookmarks_create_success: 'Segnalibro creato con successo',
  com_ui_bookmarks_update_success: 'Segnalibro aggiornato con successo',
  com_ui_bookmarks_delete_success: 'Segnalibro eliminato con successo',
  com_ui_bookmarks_create_error: 'Si è verificato un errore durante la creazione del segnalibro',
  com_ui_bookmarks_update_error: 'Si è verificato un errore durante l\'aggiornamento del segnalibro',
  com_ui_bookmarks_delete_error: 'Si è verificato un errore durante l\'eliminazione del segnalibro',
  com_ui_bookmarks_add_to_conversation: 'Aggiungi alla conversazione attuale',
  com_auth_error_login:
    'Impossibile eseguire l\'accesso con le informazioni fornite. Controlla le tue credenziali e riprova.',
  com_auth_error_login_rl:
    'Troppi tentativi di accesso in un breve periodo di tempo. Riprova più tardi.',
  com_auth_error_login_ban:
    'Il tuo account è stato temporaneamente bloccato a causa di violazioni del nostro servizio.',
  com_auth_error_login_server:
    'Si è verificato un errore interno del server. Attendi qualche istante e riprova.',
  com_auth_no_account: 'Non hai un account?',
  com_auth_sign_up: 'Registrati',
  com_auth_sign_in: 'Accedi',
  com_auth_google_login: 'Continua con Google',
  com_auth_facebook_login: 'Continua con Facebook',
  com_auth_github_login: 'Continua con Github',
  com_auth_discord_login: 'Continua con Discord',
  com_auth_email: 'Email',
  com_auth_email_required: 'L\'email è obbligatoria',
  com_auth_email_min_length: 'L\'email deve essere di almeno 6 caratteri',
  com_auth_email_max_length: 'L\'email non deve superare i 120 caratteri',
  com_auth_email_pattern: 'Devi inserire un indirizzo email valido',
  com_auth_email_address: 'Indirizzo email',
  com_auth_password: 'Password',
  com_auth_password_required: 'La password è obbligatoria',
  com_auth_password_min_length: 'La password deve essere di almeno 8 caratteri',
  com_auth_password_max_length: 'La password deve essere inferiore a 128 caratteri',
  com_auth_password_forgot: 'Password dimenticata?',
  com_auth_password_confirm: 'Conferma password',
  com_auth_password_not_match: 'Le password non corrispondono',
  com_auth_continue: 'Continua',
  com_auth_create_account: 'Crea il tuo account',
  com_auth_error_create:
    'Si è verificato un errore durante il tentativo di registrare il tuo account. Riprova.',
  com_auth_full_name: 'Nome completo',
  com_auth_name_required: 'Il nome è obbligatorio',
  com_auth_name_min_length: 'Il nome deve essere di almeno 3 caratteri',
  com_auth_name_max_length: 'Il nome deve essere inferiore a 80 caratteri',
  com_auth_username: 'Nome utente (opzionale)',
  com_auth_username_required: 'Il nome utente è obbligatorio',
  com_auth_username_min_length: 'Il nome utente deve essere di almeno 2 caratteri',
  com_auth_username_max_length: 'Il nome utente deve essere inferiore a 20 caratteri',
  com_auth_already_have_account: 'Hai già un account?',
  com_auth_login: 'Accedi',
  com_auth_reset_password: 'Reimposta la tua password',
  com_auth_click: 'Clicca',
  com_auth_here: 'QUI',
  com_auth_to_reset_your_password: 'per reimpostare la tua password.',
  com_auth_reset_password_link_sent: 'Email inviata',
  com_auth_reset_password_email_sent:
    'Ti è stata inviata un\'email con ulteriori istruzioni per reimpostare la tua password.',
  com_auth_error_reset_password:
    'Si è verificato un problema durante il reset della tua password. Nessun utente trovato con l\'indirizzo email fornito. Riprova.',
  com_auth_reset_password_success: 'Reset della password avvenuto con successo',
  com_auth_login_with_new_password: 'Ora puoi accedere con la tua nuova password.',
  com_auth_error_invalid_reset_token: 'Questo token di reset della password non è più valido.',
  com_auth_click_here: 'Clicca qui',
  com_auth_to_try_again: 'per riprovare.',
  com_auth_submit_registration: 'Invia registrazione',
  com_auth_welcome_back: 'Ben tornato',
  com_auth_back_to_login: 'Torna all\'accesso',
  com_endpoint_open_menu: 'Apri menu',
  com_endpoint_bing_enable_sydney: 'Abilita Sydney',
  com_endpoint_bing_to_enable_sydney: 'Per abilitare Sydney',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing può utilizzare fino a 7k token per il "contesto", che può riferire per la conversazione. Il limite specifico non è noto ma potrebbe incontrare errori superando 7k token',
  com_endpoint_bing_system_message_placeholder:
    'ATTENZIONE: L\'uso improprio di questa funzione può farti BANNARE dall\'utilizzo di Bing! Clicca su "Messaggio di sistema" per le istruzioni complete e il messaggio predefinito se omesso, che è il preset "Sydney" considerato sicuro.',
  com_endpoint_system_message: 'Messaggio di sistema',
  com_endpoint_message: 'Messaggio',
  com_endpoint_message_not_appendable: 'Modifica il tuo messaggio o Rigenera.',
  com_endpoint_default_blank: 'predefinito: vuoto',
  com_endpoint_default_false: 'predefinito: falso',
  com_endpoint_default_creative: 'predefinito: creativo',
  com_endpoint_default_empty: 'predefinito: vuoto',
  com_endpoint_default_with_num: 'predefinito: {0}',
  com_endpoint_context: 'Contesto',
  com_endpoint_tone_style: 'Tono Stile',
  com_endpoint_token_count: 'Conteggio token',
  com_endpoint_output: 'Output',
  com_endpoint_google_temp:
    'Valori più alti = più casualità, mentre valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_google_topp:
    'Top-p cambia il modo in cui il modello seleziona i token per l\'output. I token vengono selezionati dai più probabili K (vedi parametro topK) ai meno probabili fino a quando la somma delle loro probabilità eguaglia il valore top-p.',
  com_endpoint_google_topk:
    'Top-k cambia il modo in cui il modello seleziona i token per l\'output. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (anche chiamato greedy decoding), mentre un top-k di 3 significa che il prossimo token è selezionato tra i 3 più probabili (usando la temperatura).',
  com_endpoint_google_maxoutputtokens:
    'Numero massimo di token che possono essere generati nella risposta. Specifica un valore più basso per risposte più brevi e un valore più alto per risposte più lunghe.',
  com_endpoint_google_custom_name_placeholder: 'Imposta un nome personalizzato per Google',
  com_endpoint_prompt_prefix_placeholder:
    'Imposta istruzioni personalizzate o contesto. Ignorato se vuoto.',
  com_endpoint_instructions_assistants_placeholder:
    'Sovrascrive le istruzioni dell\'assistente. Utile per modificare il comportamento su base singola.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'Imposta istruzioni o contesto aggiuntivi oltre alle istruzioni principali dell\'Assistente. Ignorato se vuoto.',
  com_endpoint_custom_name: 'Nome personalizzato',
  com_endpoint_prompt_prefix: 'Istruzioni personalizzate',
  com_endpoint_prompt_prefix_assistants: 'Istruzioni aggiuntive',
  com_endpoint_instructions_assistants: 'Sovrascrivi istruzioni',
  com_endpoint_temperature: 'Temperatura',
  com_endpoint_default: 'predefinito',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Token di output massimi',
  com_endpoint_stop: 'Sequenze di stop',
  com_endpoint_stop_placeholder: 'Separa i valori premendo `Invio`',
  com_endpoint_openai_temp:
    'Valori più alti = più casualità, mentre valori più bassi = più focalizzati e deterministici. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_openai_max:
    'I token massimi da generare. La lunghezza totale dei token di input e dei token generati è limitata dalla lunghezza del contesto del modello.',
  com_endpoint_openai_topp:
    'Un\'alternativa al campionamento con temperatura, chiamata nucleus sampling, in cui il modello considera i risultati dei token con probabilità di massa top_p. Quindi 0,1 significa che vengono considerati solo i token che compongono la massa di probabilità superiore al 10%. Consigliamo di modificare questo o la temperatura ma non entrambi.',
  com_endpoint_openai_freq:
    'Numero compreso tra -2.0 e 2.0. Valori positivi penalizzano i nuovi token basati sulla loro frequenza esistente nel testo fino a quel momento, diminuendo la probabilità del modello di ripetere la stessa riga verbatim.',
  com_endpoint_openai_pres:
    'Numero compreso tra -2.0 e 2.0. Valori positivi penalizzano i nuovi token in base a se compaiono nel testo fino a quel momento, aumentando la probabilità del modello di parlare di nuovi argomenti.',
  com_endpoint_openai_resend:
    'Invia nuovamente tutte le immagini allegate in precedenza. Nota: questo può aumentare significativamente il costo dei token e potresti incontrare errori con molti allegati di immagini.',
  com_endpoint_openai_resend_files:
    'Invia nuovamente tutti i file allegati in precedenza. Nota: questo aumenterà il costo dei token e potresti incontrare errori con molti allegati.',
  com_endpoint_openai_detail:
    'La risoluzione per le richieste Vision. "Bassa" è più economica e veloce, "Alta" è più dettagliata e costosa, e "Auto" sceglierà automaticamente tra le due in base alla risoluzione dell\'immagine.',
  com_endpoint_openai_stop: 'Fino a 4 sequenze in cui l\'API smetterà di generare ulteriori token.',
  com_endpoint_openai_custom_name_placeholder: 'Imposta un nome personalizzato per l\'IA',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Imposta istruzioni personalizzate da includere nel Messaggio di Sistema. Predefinito: nessuno',
  com_endpoint_anthropic_temp:
    'Varia da 0 a 1. Usa temp più vicino a 0 per analitica / scelta multipla, e più vicino a 1 per compiti creativi e generativi. Consigliamo di modificare questo o Top P ma non entrambi.',
  com_endpoint_anthropic_topp:
    'Top-p cambia il modo in cui il modello seleziona i token per l\'output. I token vengono selezionati dai più probabili K (vedi parametro topK) ai meno probabili fino a quando la somma delle loro probabilità eguaglia il valore top-p.',

  com_endpoint_anthropic_topk:
    'Top-k cambia il modo in cui il modello seleziona i token per l\'output. Un top-k di 1 significa che il token selezionato è il più probabile tra tutti i token nel vocabolario del modello (anche chiamato greedy decoding), mentre un top-k di 3 significa che il prossimo token è selezionato tra i 3 più probabili (usando la temperatura).',
  com_endpoint_anthropic_maxoutputtokens:
    'Numero massimo di token che possono essere generati nella risposta. Specifica un valore più basso per risposte più brevi e un valore più alto per risposte più lunghe.',
  com_endpoint_anthropic_custom_name_placeholder: 'Imposta un nome personalizzato per Anthropic',
  com_endpoint_frequency_penalty: 'Penalità di frequenza',
  com_endpoint_presence_penalty: 'Penalità di presenza',
  com_endpoint_plug_use_functions: 'Usa funzioni',
  com_endpoint_plug_resend_files: 'Reinvia file',
  com_endpoint_plug_resend_images: 'Reinvia immagini',
  com_endpoint_plug_image_detail: 'Dettaglio immagine',
  com_endpoint_plug_skip_completion: 'Salta completamento',
  com_endpoint_disabled_with_tools: 'disabilitato con strumenti',
  com_endpoint_disabled_with_tools_placeholder: 'Disabilitato con Strumenti Selezionati',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Imposta istruzioni personalizzate da includere nel Messaggio di Sistema. Predefinito: nessuno',
  com_endpoint_import: 'Importa',
  com_endpoint_set_custom_name:
    'Imposta un nome personalizzato, nel caso tu possa trovare questa preimpostazione',
  com_endpoint_preset_delete_confirm: 'Sei sicuro di voler eliminare questa preimpostazione?',
  com_endpoint_preset_clear_all_confirm:
    'Sei sicuro di voler eliminare tutte le tue preimpostazioni?',
  com_endpoint_preset_import: 'Preimpostazione importata!',
  com_endpoint_preset_import_error:
    'Si è verificato un errore durante l\'importazione della preimpostazione. Riprova.',
  com_endpoint_preset_save_error:
    'Si è verificato un errore durante il salvataggio della preimpostazione. Riprova.',
  com_endpoint_preset_delete_error:
    'Si è verificato un errore durante l\'eliminazione della preimpostazione. Riprova.',
  com_endpoint_preset_default_removed: 'non è più la preimpostazione predefinita.',
  com_endpoint_preset_default_item: 'Predefinita:',
  com_endpoint_preset_default_none: 'Nessuna preimpostazione predefinita attiva.',
  com_endpoint_preset_title: 'Preimpostazione',
  com_ui_saved: 'Salvata!',
  com_endpoint_preset_default: 'è ora la preimpostazione predefinita.',
  com_endpoint_preset: 'preimpostazione',
  com_endpoint_presets: 'preimpostazioni',
  com_endpoint_preset_selected: 'Preimpostazione attiva!',
  com_endpoint_preset_selected_title: 'Attiva!',
  com_endpoint_preset_name: 'Nome preimpostazione',
  com_endpoint_new_topic: 'Nuovo argomento',
  com_endpoint: 'Endpoint',
  com_endpoint_hide: 'Nascondi',
  com_endpoint_show: 'Mostra',
  com_endpoint_examples: 'Preimpostazioni',
  com_endpoint_completion: 'Completamento',
  com_endpoint_agent: 'Agente',
  com_endpoint_show_what_settings: 'Mostra impostazioni {0}',
  com_endpoint_export: 'Esporta',
  com_endpoint_assistant: 'Assistente',
  com_endpoint_use_active_assistant: 'Usa Assistente Attivo',
  com_endpoint_assistant_model: 'Modello Assistente',
  com_endpoint_save_as_preset: 'Salva come Preimpostazione',
  com_endpoint_presets_clear_warning:
    'Sei sicuro di voler cancellare tutte le preimpostazioni? Questa azione è irreversibile.',
  com_endpoint_not_implemented: 'Non implementato',
  com_endpoint_no_presets:
    'Ancora nessuna preimpostazione, usa il pulsante impostazioni per crearne una',
  com_endpoint_not_available: 'Nessun endpoint disponibile',
  com_endpoint_view_options: 'Visualizza opzioni',
  com_endpoint_save_convo_as_preset: 'Salva conversazione come preimpostazione',
  com_endpoint_my_preset: 'La mia preimpostazione',
  com_endpoint_agent_model: 'Modello Agente (Consigliato: GPT-3.5)',
  com_endpoint_completion_model: 'Modello Completamento (Consigliato: GPT-4)',
  com_endpoint_func_hover: 'Abilita l\'uso di Plugin come OpenAI Functions',
  com_endpoint_skip_hover:
    'Abilita la possibilità di saltare il passaggio di completamento, che rivede la risposta finale e i passaggi generati',
  com_endpoint_config_key: 'Imposta chiave API',
  com_endpoint_assistant_placeholder: 'Seleziona un Assistente dal Pannello laterale destro',
  com_endpoint_config_placeholder: 'Imposta la tua Chiave nel menu Header per chattare.',
  com_endpoint_config_key_for: 'Imposta chiave API per',
  com_endpoint_config_key_name: 'Chiave',
  com_endpoint_config_value: 'Inserisci valore per',
  com_endpoint_config_key_name_placeholder: 'Imposta prima la chiave API',
  com_endpoint_config_key_encryption: 'La tua chiave sarà crittografata ed eliminata al',
  com_endpoint_config_key_expiry: 'tempo di scadenza',
  com_endpoint_config_click_here: 'Clicca qui',
  com_endpoint_config_google_service_key: 'Chiave Account di Servizio Google',
  com_endpoint_config_google_cloud_platform: '(da Google Cloud Platform)',
  com_endpoint_config_google_api_key: 'Chiave API Google',
  com_endpoint_config_google_gemini_api: '(API Gemini)',
  com_endpoint_config_google_api_info:
    'Per ottenere la tua chiave API Generative Language (per Gemini),',
  com_endpoint_config_key_import_json_key: 'Importa chiave JSON Account di Servizio.',
  com_endpoint_config_key_import_json_key_success:
    'Chiave JSON Account di Servizio importata con successo',
  com_endpoint_config_key_import_json_key_invalid:
    'Chiave JSON Account di Servizio non valida, hai importato il file corretto?',
  com_endpoint_config_key_get_edge_key: 'Per ottenere il tuo token di accesso per Bing, accedi a',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Usa gli strumenti di sviluppo o un\'estensione mentre sei connesso al sito per copiare il contenuto del cookie _U. Se questo fallisce, segui queste',
  com_endpoint_config_key_edge_instructions: 'istruzioni',
  com_endpoint_config_key_edge_full_key_string: 'per fornire le stringhe complete dei cookie.',
  com_endpoint_config_key_chatgpt:
    'Per ottenere il tuo token di accesso per ChatGPT "Versione Gratuita", accedi a',
  com_endpoint_config_key_chatgpt_then_visit: 'poi visita',
  com_endpoint_config_key_chatgpt_copy_token: 'Copia il token di accesso.',
  com_endpoint_config_key_google_need_to: 'Devi',
  com_endpoint_config_key_google_vertex_ai: 'Abilitare Vertex AI',
  com_endpoint_config_key_google_vertex_api: 'API su Google Cloud, poi',
  com_endpoint_config_key_google_service_account: 'Creare un Account di Servizio',
  com_endpoint_config_key_google_vertex_api_role:
    'Assicurati di cliccare su "Crea e Continua" per assegnare almeno il ruolo "Utente Vertex AI". Infine, crea una chiave JSON da importare qui.',
  com_nav_welcome_assistant: 'Seleziona un Assistente',
  com_nav_welcome_message: 'Come posso aiutarti oggi?',
  com_nav_auto_scroll: 'Scorri automaticamente ai nuovi messaggi all\'apertura',
  com_nav_hide_panel: 'Nascondi il Pannello laterale più a destra',
  com_nav_modular_chat: 'Abilita il cambio di Endpoint a metà conversazione',
  com_nav_latex_parsing: 'Analizza LaTeX nei messaggi (potrebbe influire sulle prestazioni)',
  com_nav_profile_picture: 'Immagine profilo',
  com_nav_change_picture: 'Cambia immagine',
  com_nav_plugin_store: 'Store plugin',
  com_nav_plugin_install: 'Installa',
  com_nav_plugin_uninstall: 'Disinstalla',
  com_ui_add: 'Aggiungi',
  com_nav_tool_remove: 'Rimuovi',
  com_nav_tool_dialog: 'Strumenti Assistente',
  com_nav_tool_dialog_description:
    'L\'Assistente deve essere salvato per conservare le selezioni degli strumenti.',
  com_show_agent_settings: 'Mostra impostazioni Agente',
  com_show_completion_settings: 'Mostra impostazioni Completamento',
  com_hide_examples: 'Nascondi esempi',
  com_show_examples: 'Mostra esempi',
  com_nav_plugin_search: 'Cerca plugin',
  com_nav_tool_search: 'Cerca strumenti',
  com_nav_plugin_auth_error:
    'Si è verificato un errore durante il tentativo di autenticazione di questo plugin. Riprova.',
  com_nav_export_filename: 'Nome file',
  com_nav_export_filename_placeholder: 'Imposta il nome del file',
  com_nav_export_type: 'Tipo',
  com_nav_export_include_endpoint_options: 'Includi opzioni endpoint',
  com_nav_enabled: 'Abilitato',
  com_nav_not_supported: 'Non supportato',
  com_nav_export_all_message_branches: 'Esporta tutti i rami dei messaggi',
  com_nav_export_recursive_or_sequential: 'Ricorsivo o sequenziale?',
  com_nav_export_recursive: 'Ricorsivo',
  com_nav_export_conversation: 'Esporta conversazione',
  com_nav_export: 'Esporta',
  com_nav_shared_links: 'Link condivisi',
  com_nav_shared_links_manage: 'Gestisci',
  com_nav_shared_links_empty: 'Non hai link condivisi.',
  com_nav_shared_links_name: 'Nome',
  com_nav_shared_links_date_shared: 'Data condivisione',
  com_nav_my_files: 'I miei file',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistema',
  com_nav_theme_dark: 'Scuro',
  com_nav_theme_light: 'Chiaro',
  com_nav_enter_to_send: 'Premi Invio per inviare messaggi',
  com_nav_user_name_display: 'Mostra nome utente nei messaggi',
  com_nav_save_drafts: 'Salva bozze localmente',
  com_nav_show_code: 'Mostra sempre il codice quando si usa l\'interprete di codice',
  com_nav_clear_all_chats: 'Cancella tutte le chat',
  com_nav_confirm_clear: 'Conferma cancellazione',
  com_nav_close_sidebar: 'Chiudi barra laterale',
  com_nav_open_sidebar: 'Apri barra laterale',
  com_nav_send_message: 'Invia messaggio',
  com_nav_log_out: 'Disconnetti',
  com_nav_user: 'UTENTE',
  com_nav_archived_chats: 'Chat archiviate',
  com_nav_archived_chats_manage: 'Gestisci',
  com_nav_archived_chats_empty: 'Non hai chat archiviate.',
  com_nav_archive_all_chats: 'Archivia tutte le chat',
  com_nav_archive_all: 'Archivia tutto',
  com_nav_archive_name: 'Nome',
  com_nav_archive_created_at: 'DateCreated',
  com_nav_clear_conversation: 'Cancella conversazioni',
  com_nav_clear_conversation_confirm_message:
    'Sei sicuro di voler cancellare tutte le conversazioni? Questa azione è irreversibile.',
  com_nav_help_faq: 'Guida e FAQ',
  com_nav_settings: 'Impostazioni',
  com_nav_search_placeholder: 'Cerca messaggi',
  com_nav_delete_account: 'Elimina account',
  com_nav_delete_account_confirm: 'Sei sicuro di voler eliminare il tuo account?',
  com_nav_delete_account_button: 'Elimina permanentemente il mio account',
  com_nav_delete_account_email_placeholder: 'Inserisci la tua email',
  com_nav_delete_account_confirm_placeholder:
    'Per procedere, digita "DELETE" nel campo di input sottostante',
  com_dialog_delete_warning: 'ATTENZIONE: Questo cancellerà permanentemente il tuo account.',
  com_dialog_delete_data_info: 'Tutti i tuoi dati verranno eliminati.',
  com_dialog_delete_help_center: 'Per più informazioni, visita il nostro centro assistenza.',
  com_nav_info_bookmarks_rebuild:
    'Se il conteggio dei segnalibri è errato, ricostruisci le informazioni sui segnalibri. Il conteggio dei segnalibri verrà ricalcolato e i dati verranno ripristinati al loro stato corretto.',
  com_nav_setting_general: 'Generale',
  com_nav_setting_beta: 'Funzioni Beta',
  com_nav_setting_data: 'Controlli dati',
  com_nav_setting_speech: 'Voce',
  com_nav_setting_account: 'Account',
  /* The following are AI Translated */
  com_assistants_file_search: 'Ricerca File',
  com_assistants_file_search_info:
    'L\'aggiunta di archivi vettoriali per la Ricerca File non è ancora supportata. Puoi aggiungerli dal Provider Playground o allegare file ai messaggi per la ricerca file su base di thread.',
  com_assistants_non_retrieval_model:
    'La ricerca di file non è abilitata su questo modello. Seleziona un altro modello.',
  com_ui_attach_error_openai: 'Non è possibile allegare file dell\'Assistente ad altri endpoint',
  com_ui_attach_warn_endpoint:
    'Attenzione: i file non compatibili con lo strumento potrebbero essere ignorati',
  com_ui_assistant_deleted: 'Assistente eliminato con successo',
  com_ui_assistant_delete_error: 'Si è verificato un errore durante l\'eliminazione dell\'assistente',
  com_ui_copied: 'Copiato!',
  com_ui_copy_code: 'Copia codice',
  com_nav_source_chat: 'Visualizza chat sorgente',
  com_ui_date_today: 'Oggi',
  com_ui_date_yesterday: 'Ieri',
  com_ui_date_previous_7_days: 'Ultimi 7 giorni',
  com_ui_date_previous_30_days: 'Ultimi 30 giorni',
  com_ui_date_january: 'Gennaio',
  com_ui_date_february: 'Febbraio',
  com_ui_date_march: 'Marzo',
  com_ui_date_april: 'Aprile',
  com_ui_date_may: 'Maggio',
  com_ui_date_june: 'Giugno',
  com_ui_date_july: 'Luglio',
  com_ui_date_august: 'Agosto',
  com_ui_date_september: 'Settembre',
  com_ui_date_october: 'Ottobre',
  com_ui_date_november: 'Novembre',
  com_ui_date_december: 'Dicembre',
  com_ui_nothing_found: 'Non è stato trovato nulla',
  com_ui_go_to_conversation: 'Vai alla conversazione',
  com_user_message: 'Mostra nome utente nei messaggi',
  com_ui_fork: 'Duplica',
  com_ui_mention: 'Menziona un endpoint, assistente o preset per passare rapidamente ad esso',
  com_ui_accept: 'Accetto',
  com_ui_decline: 'Non accetto',
  com_ui_terms_and_conditions: 'Termini d\'uso',
  com_ui_no_terms_content: 'Nessun contenuto dei termini d\'uso da visualizzare',
  com_endpoint_context_tokens: 'Token di Contesto Massimi',
  com_endpoint_context_info:
    'Il numero massimo di token che possono essere utilizzati per il contesto. Usalo per controllare quanti token vengono inviati per richiesta. Se non specificato, verranno utilizzate le impostazioni di sistema predefinite in base alle dimensioni del contesto dei modelli noti. Impostare valori più alti potrebbe causare errori e/o costi di token più elevati.',
  com_endpoint_openai_max_tokens:
    'Campo `max_tokens` opzionale, che rappresenta il numero massimo di token che possono essere generati nel completamento della chat.\n\nLa lunghezza totale dei token di input e dei token generati è limitata dalla lunghezza del contesto del modello. Potresti riscontrare errori se questo numero supera il massimo dei token di contesto.',
  com_nav_language: 'Lingua',
  com_nav_lang_auto: 'Rileva automaticamente',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
  com_nav_convo_menu_options: 'Opzioni menu conversazione',
  com_ui_artifacts: 'Artefatti',
  com_ui_artifacts_toggle: 'Mostra/Nascondi Interfaccia Artefatti',
  com_nav_info_code_artifacts:
    'Abilita la visualizzazione di artefatti di codice sperimentali accanto alla chat',
  com_ui_include_shadcnui: 'Includi istruzioni per i componenti shadcn/ui',
  com_ui_custom_prompt_mode: 'Modalità Prompt Personalizzato',
  com_ui_artifact_click: 'Clicca per aprire',
  com_nav_info_include_shadcnui:
    'Quando abilitato, verranno incluse le istruzioni per l\'utilizzo dei componenti shadcn/ui. shadcn/ui è una raccolta di componenti riutilizzabili costruiti utilizzando Radix UI e Tailwind CSS. Nota: queste sono istruzioni dettagliate, dovresti abilitarle solo se ritieni importante informare l\'LLM sulle corrette importazioni e sui componenti. Per maggiori informazioni su questi componenti, visita: https://ui.shadcn.com/',
  com_a11y_start: 'L\'IA ha iniziato la sua risposta',
  com_nav_info_custom_prompt_mode:
    'Quando attivata, l\'istruzione predefinita del sistema per gli artefatti non verrà inclusa. In questa modalità, tutte le istruzioni per la generazione degli artefatti dovranno essere fornite manualmente.',
  com_a11y_ai_composing: 'L\'IA sta ancora componendo',
  com_a11y_end: 'L\'IA ha terminato la sua risposta',
  com_error_invalid_request_error:
    'Il servizio AI ha rifiutato la richiesta a causa di un errore. Questo potrebbe essere causato da una chiave API non valida o da una richiesta formattata in modo errato.',
  com_error_no_system_messages:
    'Il servizio o modello di IA selezionato non supporta i messaggi di sistema. Prova a utilizzare i prompt invece delle istruzioni personalizzate.',
  com_error_files_empty: 'I file vuoti non sono consentiti.',
  com_warning_resubmit_unsupported:
    'Il reinvio del messaggio AI non è supportato per questo endpoint.',
  com_error_files_dupe: 'File duplicato rilevato.',
  com_error_input_length:
    'Il conteggio dei token dell\'ultimo messaggio è troppo lungo e supera il limite consentito ({0}). Per favore, accorcia il tuo messaggio, modifica la dimensione massima del contesto dai parametri della conversazione, oppure crea una diramazione della conversazione per continuare.',
  com_error_files_validation: 'Si è verificato un errore durante la validazione del file.',
  com_error_files_upload: 'Si è verificato un errore durante il caricamento del file.',
  com_error_files_process: 'Si è verificato un errore durante l\'elaborazione del file.',
  com_generated_files: 'File generati:',
  com_error_files_upload_canceled:
    'La richiesta di caricamento del file è stata annullata. Nota: il caricamento del file potrebbe essere ancora in corso e potrebbe essere necessario eliminarlo manualmente.',
  com_download_expired: 'download scaduto',
  com_download_expires: '(clicca qui per scaricare - scade il {0})',
  com_click_to_download: 'clicca qui per scaricare',
  com_assistants_conversation_starters: 'Spunti di Conversazione',
  com_assistants_code_interpreter_info:
    'L\'Interprete Codice permette all\'assistente di scrivere ed eseguire codice. Questo strumento può elaborare file con diversi formati e tipi di dati, e generare file come grafici.',
  com_assistants_conversation_starters_placeholder: 'Inserisci un argomento di conversazione',
  com_sidepanel_agent_builder: 'Costruttore Agente',
  com_agents_name_placeholder: 'Opzionale: Il nome dell\'agente',
  com_agents_description_placeholder: 'Opzionale: Descrivi qui il tuo Agente',
  com_agents_instructions_placeholder: 'Le istruzioni di sistema utilizzate dall\'agente',
  com_agents_search_name: 'Cerca agenti per nome',
  com_agents_update_error: 'Si è verificato un errore durante l\'aggiornamento del tuo agente.',
  com_agents_create_error: 'Si è verificato un errore durante la creazione del tuo agente.',
  com_agents_missing_provider_model:
    'Seleziona un provider e un modello prima di creare un agente.',
  com_agents_allow_editing: 'Permetti ad altri utenti di modificare il tuo agente',
  com_agents_not_available: 'Agente Non Disponibile',
  com_agents_no_access: 'Non hai l\'autorizzazione per modificare questo agente.',
  com_agents_enable_file_search: 'Abilita Ricerca File',
  com_agents_file_search_info:
    'Quando abilitato, l\'agente verrà informato dei nomi esatti dei file elencati di seguito, permettendogli di recuperare il contesto pertinente da questi file.',
  com_ui_run_code: 'Esegui Codice',
  com_agents_file_search_disabled:
    'L\'Agente deve essere creato prima di caricare file per la Ricerca File.',
  com_ui_agent_already_shared_to_all: 'Questo assistente è già condiviso con tutti gli utenti',
  com_ui_agent_editing_allowed: 'Altri utenti possono già modificare questo assistente',
  com_ui_no_changes: 'Nessuna modifica da aggiornare',
  com_ui_error_connection: 'Errore di connessione al server, prova ad aggiornare la pagina.',
  com_ui_endpoint: 'Endpoint',
  com_ui_provider: 'Fornitore',
  com_ui_region: 'Regione',
  com_ui_model_parameters: 'Parametri del Modello',
  com_ui_model_save_success: 'Parametri del modello salvati con successo',
  com_ui_select_provider: 'Seleziona un provider',
  com_ui_select_region: 'Seleziona una regione',
  com_ui_select_provider_first: 'Seleziona prima un provider',
  com_ui_select_search_region: 'Cerca regione per nome',
  com_ui_prompt: 'Prompt',
  com_ui_prompt_name: 'Nome del prompt',
  com_ui_delete_prompt: 'Eliminare il prompt?',
  com_ui_prompts: 'Prompt',
  com_ui_admin: 'Amministratore',
  com_ui_simple: 'Semplice',
  com_ui_versions: 'Versioni',
  com_ui_version_var: 'Versione {0}',
  com_ui_advanced: 'Avanzate',
  com_ui_admin_settings: 'Impostazioni Amministratore',
  com_ui_error_save_admin_settings:
    'Si è verificato un errore durante il salvataggio delle impostazioni amministrative.',
  com_ui_prompt_preview_not_shared:
    'L\'autore non ha consentito la collaborazione per questo prompt.',
  com_ui_prompt_name_required: 'Il nome del prompt è obbligatorio',
  com_ui_prompt_text_required: 'Il testo è obbligatorio',
  com_ui_prompt_text: 'Testo',
  com_ui_back_to_chat: 'Torna alla Chat',
  com_ui_back_to_prompts: 'Torna ai prompt',
  com_ui_categories: 'Categorie',
  com_ui_filter_prompts_name: 'Filtra prompt per nome',
  com_ui_search_categories: 'Cerca Categorie',
  com_ui_manage: 'Gestisci',
  com_ui_variables: 'Variabili',
  com_ui_variables_info:
    'Usa le doppie parentesi graffe nel testo per creare variabili, ad esempio `{{variabile esempio}}`, da compilare successivamente quando utilizzi il prompt.',
  com_ui_special_variables: 'Variabili speciali:',
  com_ui_dropdown_variables: 'Variabili a tendina:',
  com_ui_special_variables_info:
    'Usa `{{current_date}}` per la data attuale e `{{current_user}}` per il nome del tuo account.',
  com_ui_dropdown_variables_info:
    'Crea menu a tendina personalizzati per i tuoi prompt: `{{nome_variabile:opzione1|opzione2|opzione3}}`',
  com_ui_latest_footer: 'L\'intelligenza artificiale per tutti.',
  com_ui_upload_invalid:
    'File non valido per il caricamento. Deve essere un\'immagine che non supera il limite',
  com_ui_upload_invalid_var:
    'File non valido per il caricamento. Deve essere un\'immagine non superiore a {0} MB',
  com_ui_renaming_var: 'Rinominando "{0}"',
  com_ui_read_aloud: 'Leggi ad alta voce',
  com_ui_add_model_preset: 'Aggiungi un modello o una preimpostazione per una risposta aggiuntiva',
  com_ui_loading: 'Caricamento...',
  com_ui_all_proper: 'Tutto',
  com_assistants_max_starters_reached: 'Raggiunto il numero massimo di conversazioni iniziali',
  com_ui_revoke_keys: 'Revoca Chiavi',
  com_ui_revoke_keys_confirm: 'Sei sicuro di voler revocare tutte le chiavi?',
  com_ui_revoke_key_endpoint: 'Revoca Chiave per {0}',
  com_ui_revoke_key_confirm: 'Sei sicuro di voler revocare questa chiave?',
  com_ui_chat: 'Chat',
  com_ui_controls: 'Controlli',
  com_ui_chat_history: 'Cronologia chat',
  com_ui_dashboard: 'Pannello di controllo',
  com_ui_agent_deleted: 'Agente eliminato con successo',
  com_ui_agent: 'Agente',
  com_ui_agent_delete_error: 'Si è verificato un errore durante l\'eliminazione dell\'agente',
  com_ui_delete_agent_confirm: 'Sei sicuro di voler eliminare questo agente?',
  com_ui_agents: 'Agenti',
  com_ui_create_prompt: 'Crea prompt',
  com_ui_share_var: 'Condividi {0}',
  com_ui_share_to_all_users: 'Condividi con tutti gli utenti',
  com_ui_my_prompts: 'I miei prompt',
  com_ui_enter_var: 'Inserisci {0}',
  com_ui_shared_prompts: 'Prompt condivisi',
  com_ui_no_category: 'Nessuna categoria',
  com_ui_prompts_allow_use: 'Consenti uso dei prompt',
  com_ui_prompts_allow_create: 'Consenti creazione Prompt',
  com_ui_prompt_shared_to_all: 'Questo prompt è condiviso con tutti gli utenti',
  com_ui_prompts_allow_share_global: 'Consenti la condivisione dei prompt con tutti gli utenti',
  com_ui_prompt_update_error: 'Si è verificato un errore durante l\'aggiornamento del prompt',
  com_ui_prompt_already_shared_to_all: 'Questo prompt è già stato condiviso con tutti gli utenti',
  com_ui_command_placeholder:
    'Opzionale: Inserisci un comando per il prompt o verrà utilizzato il nome',
  com_ui_description_placeholder: 'Opzionale: Inserisci una descrizione da mostrare per il prompt',
  com_ui_command_usage_placeholder: 'Seleziona un prompt tramite comando o nome',
  com_ui_no_prompt_description: 'Nessuna descrizione disponibile',
  com_ui_delete_tool: 'Elimina Strumento',
  com_ui_delete_action: 'Elimina Azione',
  com_ui_delete_tool_confirm: 'Sei sicuro di voler eliminare questo strumento?',
  com_ui_delete_action_confirm: 'Sei sicuro di voler eliminare questa azione?',
  com_ui_delete_confirm_prompt_version_var:
    'Questo eliminerà la versione selezionata per "{0}". Se non esistono altre versioni, il prompt verrà eliminato.',
  com_ui_use_micrphone: 'Usa microfono',
  com_ui_bookmarks_create_exists: 'Questo segnalibro esiste già',
  com_ui_bookmarks_filter: 'Filtra segnalibri...',
  com_ui_bookmarks_delete: 'Elimina Segnalibro',
  com_ui_no_bookmarks:
    'Sembra che tu non abbia ancora segnalibri. Clicca su una chat e aggiungine uno nuovo',
  com_ui_no_conversation_id: 'Nessun ID conversazione trovato',
  com_ui_add_multi_conversation: 'Aggiungi conversazioni multiple',
  com_auth_error_login_unverified:
    'Il tuo account non è stato verificato. Controlla la tua email per il link di verifica.',
  com_auth_registration_success_insecure: 'Registrazione completata con successo.',
  com_auth_registration_success_generic:
    'Controlla la tua email per verificare il tuo indirizzo di posta elettronica.',
  com_auth_reset_password_if_email_exists:
    'Se esiste un account associato a questa email, ti abbiamo inviato un\'email con le istruzioni per reimpostare la password. Ricordati di controllare anche la cartella spam.',
  com_auth_email_verification_failed: 'Verifica email fallita',
  com_auth_email_verification_rate_limited: 'Troppe richieste. Per favore riprova più tardi',
  com_auth_email_verification_success: 'Email verificata con successo',
  com_auth_email_resent_success: 'Email di verifica reinviata con successo',
  com_auth_email_resent_failed: 'Impossibile reinviare l\'email di verifica',
  com_auth_email_verification_failed_token_missing: 'Verifica fallita, token mancante',
  com_auth_email_verification_invalid: 'Verifica email non valida',
  com_auth_email_verification_in_progress: 'Verifica dell\'email in corso, attendere prego',
  com_auth_email_verification_resend_prompt: 'Non hai ricevuto l\'email?',
  com_auth_email_resend_link: 'Reinvia email',
  com_auth_email_verification_redirecting: 'Reindirizzamento in {0} secondi...',
  com_endpoint_anthropic_prompt_cache:
    'La cache dei prompt permette di riutilizzare contesti o istruzioni estese tra le chiamate API, riducendo costi e latenza',
  com_endpoint_prompt_cache: 'Usa Cache dei Prompt',
  com_endpoint_export_share: 'Esporta/Condividi',
  com_endpoint_search: 'Cerca endpoint per nome',
  com_endpoint_config_key_never_expires: 'La tua chiave non scadrà mai',
  com_nav_font_size: 'Dimensione carattere messaggi',
  com_nav_account_settings: 'Impostazioni account',
  com_nav_font_size_sm: 'Piccolo',
  com_nav_font_size_lg: 'Grande',
  com_nav_font_size_base: 'Medio',
  com_nav_font_size_xs: 'Extra Small',
  com_nav_user_msg_markdown: 'Visualizza i messaggi utente in markdown',
  com_nav_text_to_speech: 'Da testo a voce',
  com_nav_font_size_xl: 'Extra Large',
  com_nav_automatic_playback: 'Riproduzione automatica ultimo messaggio',
  com_nav_speech_to_text: 'Da voce a testo',
  com_nav_tool_dialog_agents: 'Strumenti Agente',
  com_ui_misc: 'Varie',
  com_ui_idea: 'Idee',
  com_ui_write: 'Scrittura',
  com_ui_roleplay: 'Gioco di ruolo',
  com_ui_shop: 'Acquisti',
  com_ui_finance: 'Finanza',
  com_ui_travel: 'Viaggio',
  com_ui_code: 'Codice',
  com_ui_drag_drop_file: 'Trascina e rilascia un file qui',
  com_ui_teach_or_explain: 'Apprendimento',
  com_ui_select_file: 'Seleziona un file',
  com_ui_upload_image: 'Carica un\'immagine',
  com_ui_select_a_category: 'Nessuna categoria selezionata',
  com_ui_clear_all: 'Cancella tutto',
  com_nav_chat_direction: 'Direzione della chat',
  com_nav_auto_send_prompts: 'Invio automatico dei prompt',
  com_nav_always_make_prod: 'Rendi sempre produttive le nuove versioni',
  com_nav_clear_cache_confirm_message: 'Sei sicuro di voler svuotare la cache?',
  com_nav_delete_warning: 'ATTENZIONE: Questa azione eliminerà definitivamente il tuo account.',
  com_nav_stop_generating: 'Interrompi generazione',
  com_nav_delete_data_info: 'Tutti i tuoi dati verranno eliminati.',
  com_nav_conversation_mode: 'Modalità Conversazione',
  com_nav_auto_send_text: 'Invio automatico del testo',
  com_nav_auto_send_text_disabled: 'imposta -1 per disabilitare',
  com_nav_auto_transcribe_audio: 'Trascrivi audio automaticamente',
  com_nav_db_sensitivity: 'Sensibilità decibel',
  com_nav_playback_rate: 'Velocità di riproduzione audio',
  com_nav_audio_play_error: 'Errore durante la riproduzione audio: {0}',
  com_nav_audio_process_error: 'Errore durante l\'elaborazione dell\'audio: {0}',
  com_nav_long_audio_warning: 'I testi più lunghi richiederanno più tempo per l\'elaborazione.',
  com_nav_tts_init_error: 'Impossibile inizializzare la sintesi vocale: {0}',
  com_nav_tts_unsupported_error:
    'La sintesi vocale per il motore selezionato non è supportata in questo browser.',
  com_nav_source_buffer_error:
    'Errore durante l\'impostazione della riproduzione audio. Aggiorna la pagina.',
  com_nav_media_source_init_error:
    'Impossibile preparare il lettore audio. Controlla le impostazioni del browser.',
  com_nav_buffer_append_error:
    'Problema con lo streaming audio. La riproduzione potrebbe essere interrotta.',
  com_nav_speech_cancel_error:
    'Impossibile interrompere la riproduzione audio. Potrebbe essere necessario aggiornare la pagina.',
  com_nav_voices_fetch_error:
    'Impossibile recuperare le opzioni vocali. Controlla la tua connessione internet.',
  com_nav_browser: 'Browser',
  com_nav_engine: 'Motore',
  com_nav_edge: 'Edge',
  com_nav_delete_cache_storage: 'Elimina cache TTS',
  com_nav_enable_cache_tts: 'Abilita cache TTS',
  com_nav_voice_select: 'Voce',
  com_nav_enable_cloud_browser_voice: 'Usa voci basate su cloud',
  com_nav_info_enter_to_send:
    'Quando attivo, premendo `INVIO` invierai il tuo messaggio. Quando disattivato, premendo Invio andrai a capo, e dovrai premere `CTRL + INVIO` / `⌘ + INVIO` per inviare il messaggio.',
  com_nav_info_save_draft:
    'Quando attivata, questa funzione salverà automaticamente come bozze il testo e gli allegati inseriti nella chat. Queste bozze saranno disponibili anche se ricarichi la pagina o passi a un\'altra conversazione. Le bozze vengono memorizzate localmente sul tuo dispositivo e vengono eliminate una volta inviato il messaggio.',
  com_nav_info_fork_change_default:
    '"Solo messaggi visibili" include solo il percorso diretto al messaggio selezionato. "Includi rami correlati" aggiunge i rami lungo il percorso. "Includi tutti i messaggi da/verso qui" include tutti i messaggi e i rami connessi.',
  com_nav_info_fork_split_target_setting:
    'Quando abilitato, la duplicazione inizierà dal messaggio di destinazione fino all\'ultimo messaggio della conversazione, secondo il comportamento selezionato.',
  com_nav_info_user_name_display:
    'Quando attivato, il nome utente del mittente verrà mostrato sopra ogni messaggio che invii. Quando disattivato, vedrai solo "Tu" sopra i tuoi messaggi.',
  com_nav_info_latex_parsing:
    'Quando attivato, il codice LaTeX nei messaggi verrà visualizzato come equazioni matematiche. Disattivarlo può migliorare le prestazioni se non hai bisogno della visualizzazione LaTeX.',
  com_nav_info_revoke:
    'Questa azione revocherà e rimuoverà tutte le chiavi API che hai fornito. Dovrai reinserire queste credenziali per continuare a utilizzare questi endpoint.',
  com_nav_info_delete_cache_storage:
    'Questa azione eliminerà tutti i file audio TTS (Text-to-Speech) memorizzati nella cache del tuo dispositivo. I file audio in cache vengono utilizzati per velocizzare la riproduzione dell\'audio TTS già generato in precedenza, ma possono occupare spazio di archiviazione sul tuo dispositivo.',
  com_nav_chat_commands: 'Comandi Chat',
  com_nav_chat_commands_info:
    'Questi comandi vengono attivati digitando caratteri specifici all\'inizio del tuo messaggio. Ogni comando viene attivato dal suo prefisso designato. Puoi disabilitarli se usi frequentemente questi caratteri per iniziare i messaggi.',
  com_nav_commands: 'Comandi',
  com_nav_commands_tab: 'Impostazioni comandi',
  com_nav_at_command: 'Comando @',
  com_nav_at_command_description:
    'Attiva il comando "@" per cambiare endpoint, modelli, preset e altro',
  com_nav_plus_command: '+-Comando',
  com_nav_slash_command: '/-Comando',
  com_nav_plus_command_description:
    'Attiva/disattiva il comando "+" per aggiungere un\'impostazione multi-risposta',
  com_nav_slash_command_description:
    'Attiva il comando "/" per selezionare un prompt tramite tastiera',
  com_nav_command_settings: 'Impostazioni dei comandi',
  com_nav_command_settings_description: 'Personalizza i comandi disponibili nella chat',
  com_nav_no_search_results: 'Nessun risultato trovato',
  com_nav_setting_chat: 'Chat',
  com_nav_external: 'Esterno',
  com_ui_librechat_code_api_title: 'Esegui Codice AI',
  com_ui_enter_api_key: 'Inserisci API Key',
  com_ui_librechat_code_api_subtitle: 'Sicuro. Multilingue. Gestione File.',
  com_ui_librechat_code_api_key: 'Ottieni la tua chiave API per l\'Interprete di Codice LibreChat',
  com_error_invalid_action_error:
    'Richiesta negata: il dominio dell\'azione specificata non è consentito.',
  com_error_files_unsupported_capability:
    'Nessuna funzionalità abilitata che supporti questo tipo di file.',
  com_sidepanel_select_agent: 'Seleziona un Agente',
  com_agents_code_interpreter_title: 'API Interprete Codice',
  com_agents_by_librechat: 'da LibreChat',
  com_agents_code_interpreter:
    'Quando abilitato, permette al tuo agente di utilizzare l\'API LibreChat Code Interpreter per eseguire codice generato in modo sicuro, inclusa l\'elaborazione dei file. Richiede una chiave API valida.',
  com_ui_endpoint_menu: 'Menu Endpoint LLM',
  com_ui_endpoints_available: 'Endpoint Disponibili',
  com_ui_export_convo_modal: 'Esporta Conversazione',
  com_ui_llms_available: 'LLM Disponibili',
  com_ui_reset_var: 'Reimposta {0}',
  com_ui_llm_menu: 'Menu LLM',
  com_ui_select_search_provider: 'Cerca provider per nome',
  com_ui_upload_type: 'Seleziona Tipo di Caricamento',
  com_ui_upload_file_search: 'Carica per ricerca file',
  com_ui_upload_image_input: 'Carica immagine',
  com_ui_upload_code_files: 'Carica per l\'Interprete di Codice',
  com_ui_role_select: 'Ruolo',
  com_ui_admin_access_warning:
    'La disattivazione dell\'accesso amministratore a questa funzionalità potrebbe causare problemi imprevisti all\'interfaccia utente che richiedono un aggiornamento. Una volta salvata, l\'unico modo per ripristinare è attraverso l\'impostazione dell\'interfaccia nel file di configurazione librechat.yaml, che influisce su tutti i ruoli.',
  com_ui_zoom: 'Zoom',
  com_ui_run_code_error: 'Si è verificato un errore durante l\'esecuzione del codice',
  com_ui_duplication_success: 'Conversazione duplicata con successo',
  com_ui_duplication_processing: 'Duplicazione conversazione in corso...',
  com_ui_duplication_error: 'Si è verificato un errore durante la duplicazione della conversazione',
  com_ui_logo: '{0} Logo',
  com_ui_agents_allow_share_global: 'Consenti la condivisione degli Agenti con tutti gli utenti',
  com_ui_agents_allow_use: 'Consenti utilizzo Agenti',
  com_ui_agents_allow_create: 'Consenti creazione Agenti',
  com_ui_agent_duplicated: 'Agente duplicato con successo',
  com_ui_duplicate: 'Duplica',
  com_ui_agent_duplicate_error: 'Si è verificato un errore durante la duplicazione dell\'assistente',
  com_ui_more_info: 'Maggiori informazioni',
  com_ui_schema: 'Schema',
  com_ui_enter_openapi_schema: 'Inserisci qui il tuo schema OpenAPI',
  com_ui_privacy_policy_url: 'URL Informativa Privacy',
  com_ui_duplicate_agent_confirm: 'Sei sicuro di voler duplicare questo agente?',
  com_nav_welcome_agent: 'Seleziona un Assistente',
  com_endpoint_agent_placeholder: 'Seleziona un Agente',
  com_ui_delete_shared_link: 'Eliminare il link condiviso?',
  com_ui_bookmarks_add: 'Aggiungi Segnalibri',
  com_ui_bookmarks_edit: 'Modifica Segnalibro',
  com_endpoint_message_new: 'Messaggio {0} oppure digita "@" per cambiare IA',
  com_endpoint_ai: 'IA',
  com_nav_maximize_chat_space: 'Massimizza spazio chat',
  com_ui_page: 'Pagina',
  com_ui_collapse_chat: 'Comprimi Chat',
};
