import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useMemo, useState } from 'react';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import {
  FileSources,
  LocalStorageKeys,
  getConfigDefaults,
} from 'librechat-data-provider';
import type { ExtendedFile } from '~/common';
import { useDragHelpers, useSetFilesToDelete, useMediaQuery } from '~/hooks';
import DragDropOverlay from './Input/Files/DragDropOverlay';
import DragDropWrapper from '~/components/Chat/Input/Files/DragDropWrapper';
import { useDeleteFilesMutation } from '~/data-provider';
import {
  ResizableHandleAlt,
  ResizablePanel,
  ResizablePanelGroup,
} from '~/components/ui/Resizable';
import Artifacts from '~/components/Artifacts/Artifacts';
import { SidePanel } from '~/components/SidePanel';
import { cn } from '~/utils';
import store from '~/store';

const defaultInterface = getConfigDefaults().interface;

export default function Presentation({
  children,
  useSidePanel = false,
  panel,
}: {
  children: React.ReactNode;
  panel?: React.ReactNode;
  useSidePanel?: boolean;
}) {
  const { data: startupConfig } = useGetStartupConfig();
  const artifacts = useRecoilValue(store.artifactsState);
  const codeArtifacts = useRecoilValue(store.codeArtifacts);
  const hideSidePanel = useRecoilValue(store.hideSidePanel);
  const artifactsVisible = useRecoilValue(store.artifactsVisible);
  const setArtifactsVisible = useSetRecoilState(store.artifactsVisible);
  const isMobile = useMediaQuery('(max-width: 767px)');
  const isSmallScreen = useMediaQuery('(max-width: 350px)');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (isMobile && artifactsVisible) {
      setShowPopup(true);
    }
  }, [artifactsVisible, isMobile]);

  const interfaceConfig = useMemo(
    () => startupConfig?.interface ?? defaultInterface,
    [startupConfig],
  );

  const setFilesToDelete = useSetFilesToDelete();

  const { mutateAsync } = useDeleteFilesMutation({
    onSuccess: () => {
      console.log('Temporary Files deleted');
      setFilesToDelete({});
    },
    onError: (error) => {
      console.log('Error deleting temporary files:', error);
    },
  });

  useEffect(() => {
    const filesToDelete = localStorage.getItem(
      LocalStorageKeys.FILES_TO_DELETE,
    );
    const map = JSON.parse(filesToDelete ?? '{}') as Record<
      string,
      ExtendedFile
    >;
    const files = Object.values(map)
      .filter(
        (file) =>
          file.filepath != null &&
          file.source &&
          !(file.embedded ?? false) &&
          file.temp_file_id,
      )
      .map((file) => ({
        file_id: file.file_id,
        filepath: file.filepath as string,
        source: file.source as FileSources,
        embedded: !!(file.embedded ?? false),
      }));

    if (files.length === 0) {
      return;
    }
    mutateAsync({ files });
  }, [mutateAsync]);

  const defaultLayout = useMemo(() => {
    const resizableLayout = localStorage.getItem(
      'react-resizable-panels:layout',
    );
    return typeof resizableLayout === 'string'
      ? JSON.parse(resizableLayout)
      : undefined;
  }, []);
  const defaultCollapsed = useMemo(() => {
    const collapsedPanels = localStorage.getItem(
      'react-resizable-panels:collapsed',
    );
    return typeof collapsedPanels === 'string'
      ? JSON.parse(collapsedPanels)
      : true;
  }, []);
  const fullCollapse = useMemo(
    () => localStorage.getItem('fullPanelCollapse') === 'true',
    [],
  );

  const showArtifacts =
    artifactsVisible === true &&
    codeArtifacts === true &&
    Object.keys(artifacts ?? {}).length > 0;

  const layout = () => (
    <div className="transition-width relative flex h-full w-full flex-1 flex-col items-stretch overflow-hidden bg-white pt-0 dark:bg-[#0f172a]">
      <div className="flex h-full flex-col" role="presentation">
        {children}
      </div>
    </div>
  );

  const closePopup = () => {
    setShowPopup(false);
    setArtifactsVisible(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' || event.key === 'Space') {
      closePopup();
    }
  };

  if (useSidePanel && !hideSidePanel && interfaceConfig.sidePanel === true) {
    return (
      <DragDropWrapper
        // ref={drop}
        className="relative flex w-full grow overflow-hidden bg-white dark:bg-[#0f172a]"
      >
        <SidePanel
          defaultLayout={defaultLayout}
          defaultCollapsed={defaultCollapsed}
          fullPanelCollapse={fullCollapse}
          artifacts={showArtifacts ? <Artifacts /> : null}
        >
          <main className="flex h-full flex-col overflow-y-auto" role="main">
            {children}
          </main>
        </SidePanel>
      </DragDropWrapper>
    );
  }

  return (
    <DragDropWrapper
      // ref={drop}
      className="relative flex w-full grow overflow-hidden bg-white dark:bg-[#0f172a]"
    >
      {isMobile ? (
        <div className="w-full">
          {layout()}
          {panel != null && panel}
        </div>
      ) : (
        <ResizablePanelGroup direction="horizontal">
          <ResizablePanel defaultSize={55} minSize={30}>
            {layout()}
            {panel != null && panel}
          </ResizablePanel>
          {showArtifacts && (
            <>
              <ResizableHandleAlt
                withHandle
                className="bg-border-medium ml-3 dark:text-white"
              />
              <ResizablePanel defaultSize={45} minSize={20}>
                <div className="border-border-medium h-full border-l">
                  <Artifacts />
                </div>
              </ResizablePanel>
            </>
          )}
        </ResizablePanelGroup>
      )}

      {/* Mobile Popup */}
      {isMobile && showArtifacts && (
        <>
          <div
            role="button"
            tabIndex={0}
            className={cn(
              'fixed inset-0 z-40 bg-black/50 transition-opacity duration-200',
              showPopup ? 'opacity-100' : 'pointer-events-none opacity-0',
            )}
            onClick={closePopup}
            onKeyDown={handleKeyDown}
            aria-label="Close artifacts popup"
          />
          {/* Artifacts Modal */}
          <div
            className={cn(
              'fixed z-50 bg-white shadow-xl transition-all duration-200 dark:bg-[#0f172a]',
              isSmallScreen ? 'inset-0' : 'inset-0 m-2',
              'overflow-hidden rounded-none sm:rounded-lg',
              showPopup
                ? 'translate-y-0 opacity-100'
                : 'pointer-events-none translate-y-4 opacity-0',
            )}
          >
            <div className="flex h-full flex-col">
              <div className="flex-1 overflow-auto overscroll-contain">
                <Artifacts />
              </div>
            </div>
          </div>
        </>
      )}
    </DragDropWrapper>
  );
}
