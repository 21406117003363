// Turkish phrases
// file deepcode ignore NoHardcodedPasswords: No hardcoded values present in this file
// file deepcode ignore HardcodedNonCryptoSecret: No hardcoded secrets present in this file

export default {
  com_error_moderation:
    'Gönderdiğiniz içerik, topluluk kurallarımıza uymadığı için moderasyon sistemimiz tarafından işaretlenmiş görünüyor. Bu belirli konu ile devam edemiyoruz. Başka sorularınız veya incelemek istediğiniz başka konular varsa, mesajınızı düzenleyin veya yeni bir konuşma başlatın.',
  com_error_no_user_key: 'Anahtar bulunamadı. Lütfen bir anahtar sağlayın ve tekrar deneyin.',
  com_error_no_base_url: 'Temel URL bulunamadı. Lütfen bir tane sağlayın ve tekrar deneyin.',
  com_error_invalid_user_key:
    'Sağlanan anahtar geçersiz. Lütfen bir anahtar sağlayın ve tekrar deneyin.',
  com_error_expired_user_key:
    'Belirtilen {0} anahtarı {1} tarihinde süresi dolmuş. Lütfen bir anahtar sağlayın ve tekrar deneyin.',
  com_files_no_results: 'Sonuç bulunamadı.',
  com_files_filter: 'Dosyaları filtrele...',
  com_files_number_selected: '{0} dosya/dosyadan {1} seçildi',
  com_sidepanel_select_assistant: 'Bir Asistan Seç',
  com_sidepanel_parameters: 'Parametreler',
  com_sidepanel_assistant_builder: 'Asistan Yapıcı',
  com_sidepanel_hide_panel: 'Paneli Gizle',
  com_sidepanel_attach_files: 'Dosyaları Ekle',
  com_sidepanel_manage_files: 'Dosyaları Yönet',
  com_assistants_capabilities: 'Yetenekler',
  com_assistants_file_search: 'Dosya Arama',
  com_assistants_file_search_info:
    'Dosya Araması için vektör mağazalarını eklemek henüz desteklenmiyor. Bunları Sağlayıcı Oyun Alanı\'ndan ekleyebilir veya mesajlar için dosya ekleyerek konu bazında dosya arayabilirsin.',
  com_assistants_knowledge: 'Bilgi',
  com_assistants_knowledge_info:
    'Dosyaları Bilgi altına yüklersen, Asistan ile yapılan konuşmalar dosya içeriklerini içerebilir.',
  com_assistants_knowledge_disabled:
    'Bilgi olarak dosya yüklemeden önce, Asistan oluşturulmalı ve Kod Yorumlayıcı veya Geri Getirme etkinleştirilip kaydedilmelidir.',
  com_assistants_image_vision: 'Görüntü Vizyonu',
  com_assistants_append_date: 'Şu anki tarih ve saati ekleyin',
  com_assistants_append_date_tooltip:
    'Etkinleştirildiğinde, şu anki müşteri tarihi ve saati, Asistanın sistem talimatlarına eklenir.',
  com_assistants_code_interpreter: 'Kod Yorumlayıcı',
  com_assistants_code_interpreter_files:
    'Aşağıdaki dosyalar yalnızca Kod Yorumlayıcı için kullanılabilir:',
  com_assistants_retrieval: 'Geri Getirme',
  com_assistants_search_name: 'Asistan adında ara',
  com_ui_tools: 'Araçlar',
  com_assistants_actions: 'Eylemler',
  com_assistants_add_tools: 'Araçları Ekle',
  com_assistants_add_actions: 'Eylem Ekle',
  com_assistants_non_retrieval_model:
    'Dosya arama bu modelde etkin değil. Lütfen başka bir model seçin.',
  com_assistants_available_actions: 'Mevcut Eylemler',
  com_assistants_running_action: 'Eylem çalışıyor',
  com_assistants_completed_action: '{0} ile konuştu',
  com_assistants_completed_function: '{0} yürütüldü',
  com_assistants_function_use: 'Asistan {0} kullandı',
  com_assistants_domain_info: 'Asistan bu bilgiyi {0} adresine gönderdi',
  com_assistants_delete_actions_success: 'Asistandan Eylem başarıyla silindi',
  com_assistants_update_actions_success: 'Eylem başarıyla oluşturuldu veya güncellendi',
  com_assistants_update_actions_error: 'Eylem oluşturma veya güncelleme sırasında bir hata oluştu.',
  com_assistants_delete_actions_error: 'Eylem silme sırasında bir hata oluştu.',
  com_assistants_actions_info:
    'Asistanın API\'leri kullanarak bilgi getirmesine veya eylem gerçekleştirmesine izin ver',
  com_assistants_name_placeholder: 'Seçmeli: asistanın adı',
  com_assistants_instructions_placeholder: 'Asistanın kullandığı sistem talimatları',
  com_assistants_description_placeholder: 'Seçmeli: Asistanınızın açıklamasını buraya yazın',
  com_assistants_actions_disabled: 'Eylem eklemeden önce bir asistan oluşturmanız gerekiyor.',
  com_assistants_update_success: 'Başarıyla güncellendi',
  com_assistants_update_error: 'Asistanınızı güncelleme sırasında bir hata oluştu.',
  com_assistants_create_success: 'Başarıyla oluşturuldu',
  com_assistants_create_error: 'Asistanınızı oluşturma sırasında bir hata oluştu.',
  com_ui_date_today: 'Bugün',
  com_ui_date_yesterday: 'Dün',
  com_ui_date_previous_7_days: 'Önceki 7 gün',
  com_ui_date_previous_30_days: 'Önceki 30 gün',
  com_ui_date_january: 'Ocak',
  com_ui_date_february: 'Şubat',
  com_ui_date_march: 'Mart',
  com_ui_date_april: 'Nisan',
  com_ui_date_may: 'Mayıs',
  com_ui_date_june: 'Haziran',
  com_ui_date_july: 'Temmuz',
  com_ui_date_august: 'Ağustos',
  com_ui_date_september: 'Eylül',
  com_ui_date_october: 'Ekim',
  com_ui_date_november: 'Kasım',
  com_ui_date_december: 'Aralık',
  com_ui_field_required: 'Bu alan gereklidir',
  com_ui_download_error: 'Dosya indirme hatası. Dosya silinmiş olabilir.',
  com_ui_attach_error_type: 'Uç nokta için desteklenmeyen dosya türü:',
  com_ui_attach_error_openai: 'Asistan dosyalarını diğer uç noktalara ekleyemezsiniz',
  com_ui_attach_warn_endpoint:
    'Asistan olmayan dosyalar uyumlu bir araç olmadan göz ardı edilebilir',
  com_ui_attach_error_size: 'Uç nokta için dosya boyutu sınırı aşıldı:',
  com_ui_attach_error:
    'Dosya eklenemiyor. Bir konuşma oluşturun veya seçin ya da sayfayı yenilemeyi deneyin.',
  com_ui_examples: 'Örnekler',
  com_ui_new_chat: 'Yeni sohbet',
  com_ui_happy_birthday: '1. doğum günüm kutlu olsun!',
  com_ui_example_quantum_computing: 'Kuantum hesaplamayı basit terimlerle açıkla',
  com_ui_example_10_year_old_b_day:
    '10 yaşındaki bir çocuğun doğum günü için yaratıcı fikirleriniz var mı?',
  com_ui_example_http_in_js: 'JavaScript\'te nasıl HTTP isteği yaparım?',
  com_ui_capabilities: 'Yetenekler',
  com_ui_capability_remember: 'Kullanıcının önceki konuşmalarını hatırlar',
  com_ui_capability_correction: 'Takip düzeltmelerine izin verir',
  com_ui_capability_decline_requests: 'Uygunsuz talepleri reddetmek üzere eğitilmiştir',
  com_ui_limitations: 'Sınırlamalar',
  com_ui_limitation_incorrect_info: 'Zaman zaman yanlış bilgi üretebilir',
  com_ui_limitation_harmful_biased:
    'Zaman zaman zararlı talimatlar veya önyargılı içerikler üretebilir',
  com_ui_limitation_limited_2021: '2021 sonrası dünya ve olayları hakkında sınırlı bilgiye sahip',
  com_ui_experimental: 'Deneysel Özellikler',
  com_ui_on: 'Açık',
  com_ui_off: 'Kapalı',
  com_ui_yes: 'Evet',
  com_ui_no: 'Hayır',
  com_ui_ascending: 'Artan',
  com_ui_descending: 'Azalan',
  com_ui_show_all: 'Tümünü Göster',
  com_ui_name: 'Ad',
  com_ui_date: 'Tarih',
  com_ui_storage: 'Depolama',
  com_ui_context: 'Bağlam',
  com_ui_size: 'Boyut',
  com_ui_host: 'Host',
  com_ui_update: 'Güncelle',
  com_ui_authentication: 'Kimlik Doğrulama',
  com_ui_instructions: 'Talimatlar',
  com_ui_description: 'Açıklama',
  com_ui_error: 'Hata',
  com_ui_error_connection: 'Sunucuya bağlanırken hata oluştu, sayfayı yenilemeyi deneyin.',
  com_ui_select: 'Seç',
  com_ui_input: 'Girdi',
  com_ui_close: 'Kapat',
  com_ui_model: 'Model',
  com_ui_select_model: 'Bir model seçin',
  com_ui_select_search_model: 'Adına göre model ara',
  com_ui_select_search_plugin: 'Adına göre eklenti ara',
  com_ui_use_prompt: 'İstemi kullan',
  com_ui_prev: 'Önceki',
  com_ui_next: 'Sonraki',
  com_ui_stop: 'Durdur',
  com_ui_upload_files: 'Dosyaları yükle',
  com_ui_prompt_templates: 'İstem Şablonları',
  com_ui_hide_prompt_templates: 'İstem Şablonlarını Gizle',
  com_ui_showing: 'Gösteriliyor',
  com_ui_of: '-den',
  com_ui_entries: 'Girişler',
  com_ui_pay_per_call: 'Tüm AI konuşmaları tek bir yerde. Aylık bazda değil, çağrı başına ödeme',
  com_ui_new_footer: 'Tüm AI konuşmaları tek bir yerde.',
  com_ui_enter: 'Gir',
  com_ui_submit: 'Gönder',
  com_ui_none_selected: 'Hiçbiri seçilmedi',
  com_ui_upload_success: 'Dosya başarıyla yüklendi',
  com_ui_upload_error: 'Dosyanızı yüklerken bir hata oluştu',
  com_ui_upload_invalid: 'Geçersiz dosya yükleme. 2 MB\'ı geçmeyen bir resim olması gerekir',
  com_ui_cancel: 'İptal',
  com_ui_save: 'Kaydet',
  com_ui_save_submit: 'Kaydet ve Gönder',
  com_user_message: 'Sen',
  com_ui_read_aloud: 'Sesli oku',
  com_ui_copied: 'Kopyalandı!',
  com_ui_copy_code: 'Kodu kopyala',
  com_ui_copy_to_clipboard: 'Panoya kopyala',
  com_ui_copied_to_clipboard: 'Panoya kopyalandı',
  com_ui_fork: 'Çatallaş',
  com_ui_fork_info_1: 'Mesajların istenilen davranışla çatallanması için bu ayarı kullanın.',
  com_ui_fork_info_2:
    '"Çatallaşma", mevcut konuşmadaki belirli mesajlardan veya mesajlardan başlayan/sonlanan yeni bir konuşma oluşturmayı ifade eder, seçilen seçeneklere göre bir kopya oluşturur.',
  com_ui_fork_info_3:
    '"Hedef mesaj" bu açılır pencerenin açıldığı mesajı veya "{0}" işaretlenirse, konuşmadaki son mesajı ifade eder.',
  com_ui_fork_info_visible:
    'Bu seçenek sadece görünen mesajları çatallandırır; diğer bir deyişle, hedef mesaja giden doğrudan yolu, herhangi bir dal olmadan.',
  com_ui_fork_info_branches:
    'Bu seçenek, görünen mesajları ilgili dallarla birlikte çatallandırır; diğer bir deyişle, hedef mesaja giden doğrudan yolu ve yol üzerindeki dalları içerir.',
  com_ui_fork_info_target:
    'Bu seçenek, hedef mesaja kadar olan tüm mesajları ve komşularını çatallandırır; diğer bir deyişle, tüm mesaj dalları, görünür olup olmadıklarına veya aynı yoldan olup olmadıklarına bakılmaksızın dahildir.',
  com_ui_fork_info_start:
    'İşaretlenirse, yukarıda seçilen davranışa göre bu mesajdan konuşmadaki son mesaja kadar çatallandırılacaktır.',
  com_ui_fork_info_remember:
    'Çatallama işlemlerinde hızlıca tercih ettiğiniz seçenekleri hatırlayın, böylece gelecekte çatallama işlemlerini daha hızlı yapabilirsiniz.',
  com_ui_fork_success: 'Başarıyla çatallanmış konuşma',
  com_ui_fork_processing: 'Konuşma çatallanıyor...',
  com_ui_fork_error: 'Konuşma çatallanmasında bir hata oluştu',
  com_ui_fork_change_default: 'Varsayılan çatallama seçeneği',
  com_ui_fork_default: 'Varsayılan çatallama seçeneğini kullan',
  com_ui_fork_remember: 'Hatırla',
  com_ui_fork_split_target_setting: 'Varsayılan olarak hedef mesajdan çatallamaya başla',
  com_ui_fork_split_target: 'Çatallamaya burada başla',
  com_ui_fork_remember_checked:
    'Seçiminiz kullanımdan sonra hatırlanacaktır. Ayarlardan herhangi bir zamanda bunu değiştirebilirsiniz.',
  com_ui_fork_all_target: 'Buradan tüm dahil et',
  com_ui_fork_branches: 'İlgili dalları dahil et',
  com_ui_fork_visible: 'Sadece görünen mesajlar',
  com_ui_fork_from_message: 'Bir çatallama seçeneği seç',
  com_ui_mention: 'Bir uç nokta, asistan veya hazır ayar anın, hızlıca ona geçmek için',
  com_ui_regenerate: 'Yeniden Oluştur',
  com_ui_continue: 'Devam et',
  com_ui_edit: 'Düzenle',
  com_ui_success: 'Başarı',
  com_ui_all: 'hepsi',
  com_ui_clear: 'Temizle',
  com_ui_revoke: 'Geri Al',
  com_ui_revoke_info: 'Kullanıcı tarafından sağlanan tüm kimlik bilgilerini geri al',
  com_ui_import_conversation: 'İçe Aktar',
  com_ui_nothing_found: 'Hiçbir şey bulunamadı',
  com_ui_go_to_conversation: 'Konuşmaya Git',
  com_ui_import_conversation_info: 'JSON dosyasından konuşmaları içe aktar',
  com_ui_import_conversation_success: 'Konuşmalar başarıyla içe aktarıldı',
  com_ui_import_conversation_error: 'Konuşmalarınızı içe aktarma sırasında bir hata oluştu',
  com_ui_import_conversation_file_type_error: 'Desteklenmeyen içe aktarma türü',
  com_ui_confirm_action: 'Eylemi Onayla',
  com_ui_chats: 'sohbetler',
  com_ui_avatar: 'Avatar',
  com_ui_unknown: 'Bilinmeyen',
  com_ui_result: 'Sonuç',
  com_ui_image_gen: 'Görüntü Oluştur',
  com_ui_assistant: 'Asistan',
  com_ui_assistant_deleted: 'Asistan başarıyla silindi',
  com_ui_assistant_delete_error: 'Asistan silme sırasında bir hata oluştu',
  com_ui_assistants: 'Asistanlar',
  com_ui_attachment: 'Ek',
  com_ui_assistants_output: 'Asistan Çıkışı',
  com_ui_delete: 'Sil',
  com_ui_create: 'Oluştur',
  com_ui_share: 'Paylaş',
  com_ui_copy_link: 'Bağlantıyı kopyala',
  com_ui_update_link: 'Bağlantıyı güncelle',
  com_ui_create_link: 'Bağlantı oluştur',
  com_ui_share_link_to_chat: 'Sohbete bağlantı paylaş',
  com_ui_share_error: 'Sohbet bağlantısını paylaşırken bir hata oluştu',
  com_ui_share_create_message: 'Adınız ve paylaşım sonrasında eklediğiniz mesajlar gizli kalır.',
  com_ui_share_created_message:
    'Sohbetinize paylaşılan bir bağlantı oluşturuldu. Daha önce paylaşılan sohbetleri istediğiniz zaman Ayarlar aracılığıyla yönetin.',
  com_ui_share_update_message:
    'Adınız, özel talimatlarınız ve paylaşım sonrasında eklediğiniz mesajlar gizli kalır.',
  com_ui_share_updated_message:
    'Sohbetinize paylaşılan bir bağlantı güncellendi. Daha önce paylaşılan sohbetleri istediğiniz zaman Ayarlar aracılığıyla yönetin.',
  com_ui_shared_link_not_found: 'Paylaşılan bağlantı bulunamadı',
  com_ui_delete_conversation: 'Sohbeti sil?',
  com_ui_delete_assistant_confirm:
    'Bu Asistanı gerçekten silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  com_ui_rename: 'Yeniden adlandır',
  com_ui_archive: 'Arşivle',
  com_ui_archive_error: 'Konuşmayı arşivleyemedi',
  com_ui_unarchive: 'Arşivden çıkar',
  com_ui_unarchive_error: 'Konuşmayı arşivden çıkarma başarısız oldu',
  com_ui_more_options: 'Daha fazla seçenek',
  com_ui_preview: 'Önizleme',
  com_ui_upload: 'Yükle',
  com_ui_connect: 'Bağlan',
  com_ui_locked: 'Kilitli',
  com_ui_upload_delay:
    '"{0}" yüklenmesi beklenenden daha uzun sürüyor. Lütfen dosyanın alma işlemini tamamlamasını bekleyin.',
  com_ui_privacy_policy: 'Gizlilik Politikası',
  com_ui_terms_of_service: 'Hizmet Şartları',
  com_ui_use_micrphone: 'Mikrofon kullan',
  com_ui_min_tags: 'Daha fazla değer kaldırılamaz, en az {0} gereklidir.',
  com_ui_max_tags: 'İzin verilen maksimum sayı {0}, en son değerler kullanılıyor.',
  com_ui_bookmarks: 'Yer İmleri',
  com_ui_bookmarks_rebuild: 'Yeniden İnşa Et',
  com_ui_bookmarks_new: 'Yeni Yer İmi',
  com_ui_bookmark_delete_confirm: 'Bu yer imini silmek istediğinizden emin misiniz?',
  com_ui_bookmarks_title: 'Başlık',
  com_ui_bookmarks_count: 'Adet',
  com_ui_bookmarks_description: 'Açıklama',
  com_ui_bookmarks_create_success: 'Yer imi başarıyla oluşturuldu',
  com_ui_bookmarks_update_success: 'Yer imi başarıyla güncellendi',
  com_ui_bookmarks_delete_success: 'Yer imi başarıyla silindi',
  com_ui_bookmarks_create_error: 'Yer imi oluşturulurken bir hata oluştu',
  com_ui_bookmarks_update_error: 'Yer imi güncellenirken bir hata oluştu',
  com_ui_bookmarks_delete_error: 'Yer imi silinirken bir hata oluştu',
  com_ui_bookmarks_add_to_conversation: 'Mevcut sohbete ekle',
  com_ui_accept: 'Kabul ediyorum',
  com_ui_decline: 'Kabul etmiyorum',
  com_ui_terms_and_conditions: 'Şartlar ve koşullar',
  com_ui_no_terms_content: 'Şartlar ve koşullar için içerik bulunmuyor',
  com_auth_error_login:
    'Sağlanan bilgilerle giriş yapılamıyor. Lütfen kimlik bilgilerinizi kontrol edin ve tekrar deneyin.',
  com_auth_error_login_rl:
    'Kısa sürede çok fazla giriş denemesi yapıldı. Lütfen daha sonra tekrar deneyin.',
  com_auth_error_login_ban:
    'Hesabınız hizmetimize yönelik ihlaller nedeniyle geçici olarak yasaklanmıştır.',
  com_auth_error_login_server:
    'Dahili sunucu hatası oluştu. Lütfen birkaç dakika bekleyin ve tekrar deneyin.',
  com_auth_error_login_unverified:
    'Hesabınız doğrulanmamış. Lütfen doğrulama bağlantısı için e-postanızı kontrol edin.',
  com_auth_no_account: 'Hesabınız yok mu?',
  com_auth_sign_up: 'Kaydol',
  com_auth_sign_in: 'Giriş yap',
  com_auth_google_login: 'Google ile devam et',
  com_auth_facebook_login: 'Facebook ile devam et',
  com_auth_github_login: 'Github ile devam et',
  com_auth_discord_login: 'Discord ile devam et',
  com_auth_email: 'E-posta',
  com_auth_email_required: 'E-posta gereklidir',
  com_auth_email_min_length: 'E-posta en az 6 karakter olmalıdır',
  com_auth_email_max_length: 'E-posta 120 karakteri geçmemelidir',
  com_auth_email_pattern: 'Geçerli bir e-posta adresi girmelisiniz',
  com_auth_email_address: 'E-posta adresi',
  com_auth_password: 'Şifre',
  com_auth_password_required: 'Şifre gereklidir',
  com_auth_password_min_length: 'Şifre en az 8 karakter olmalıdır',
  com_auth_password_max_length: 'Şifre en fazla 128 karakter olmalıdır',
  com_auth_password_forgot: 'Şifremi unuttum?',
  com_auth_password_confirm: 'Şifreyi onayla',
  com_auth_password_not_match: 'Şifreler uyuşmuyor',
  com_auth_continue: 'Devam et',
  com_auth_create_account: 'Hesabınızı oluşturun',
  com_auth_error_create: 'Hesabınızı kaydetmeye çalışırken bir hata oluştu. Lütfen tekrar deneyin.',
  com_auth_full_name: 'Tam adı',
  com_auth_name_required: 'Ad gereklidir',
  com_auth_name_min_length: 'Ad en az 3 karakter olmalıdır',
  com_auth_name_max_length: 'Ad en fazla 80 karakter olmalıdır',
  com_auth_username: 'Kullanıcı adı (isteğe bağlı)',
  com_auth_username_required: 'Kullanıcı adı gereklidir',
  com_auth_username_min_length: 'Kullanıcı adı en az 2 karakter olmalıdır',
  com_auth_username_max_length: 'Kullanıcı adı en fazla 20 karakter olmalıdır',
  com_auth_already_have_account: 'Zaten bir hesabınız var mı?',
  com_auth_login: 'Giriş yap',
  com_auth_registration_success_insecure: 'Kayıt başarıyla tamamlandı.',
  com_auth_registration_success_generic:
    'E-posta adresinizi doğrulamak için lütfen e-postanızı kontrol edin.',
  com_auth_reset_password: 'Şifrenizi sıfırlayın',
  com_auth_click: 'Tıklayın',
  com_auth_here: 'BURAYA',
  com_auth_to_reset_your_password: 'şifrenizi sıfırlamak için.',
  com_auth_reset_password_link_sent: 'E-posta gönderildi',
  com_auth_reset_password_if_email_exists:
    'Bu e-postaya sahip bir hesap varsa, e-posta ile şifre sıfırlama talimatları gönderilmiştir. Lütfen spam klasörünüzü kontrol edin.',
  com_auth_reset_password_email_sent:
    'Kullanıcı kayıtlıysa, posta kutusuna bir e-posta gönderilecektir.',
  com_auth_reset_password_success: 'Şifre başarıyla sıfırlandı',
  com_auth_login_with_new_password: 'Şimdi yeni şifreniz ile giriş yapabilirsiniz.',
  com_auth_error_invalid_reset_token: 'Bu şifre sıfırlama jetonu artık geçerli değil.',
  com_auth_click_here: 'Buraya tıklayın',
  com_auth_to_try_again: 'yeniden denemek için.',
  com_auth_submit_registration: 'Kayıt formunu gönder',
  com_auth_welcome_back: 'Tekrar hoş geldiniz',
  com_auth_back_to_login: 'Girişe geri dön',
  com_auth_email_verification_failed: 'E-posta doğrulama başarısız oldu',
  com_auth_email_verification_rate_limited: 'Çok fazla istek var. Lütfen daha sonra tekrar deneyin',
  com_auth_email_verification_success: 'E-posta başarıyla doğrulandı',
  com_auth_email_resent_success: 'Doğrulama e-postası başarıyla yeniden gönderildi',
  com_auth_email_resent_failed: 'Doğrulama e-postasını yeniden gönderme başarısız oldu',
  com_auth_email_verification_failed_token_missing: 'Doğrulama başarısız, jeton eksik',
  com_auth_email_verification_invalid: 'Geçersiz e-posta doğrulama',
  com_auth_email_verification_in_progress: 'E-postanızı doğruluyoruz, lütfen bekleyin',
  com_auth_email_verification_resend_prompt: 'E-postayı almadınız mı?',
  com_auth_email_resend_link: 'E-postayı yeniden gönder',
  com_auth_email_verification_redirecting: '{0} saniye içinde yönlendiriliyor...',
  com_endpoint_open_menu: 'Menüyü Aç',
  com_endpoint_bing_enable_sydney: 'Sydney\'i Etkinleştir',
  com_endpoint_bing_to_enable_sydney: 'Sydney\'i etkinleştirmek için',
  com_endpoint_bing_jailbreak: 'Jailbreak',
  com_endpoint_bing_context_placeholder:
    'Bing, konuşma için referans olarak kullanabileceği \'bağlam\' için 7 bin tokene kadar kullanabilir. Belirli limit bilinmemektedir ancak 7 bin tokenden fazla olması durumunda hatalar oluşabilir',
  com_endpoint_bing_system_message_placeholder:
    'UYARI: Bu özelliği kötüye kullanmak Bing kullanımınız için YASAKLANMANIZA sebep olabilir! Varsayılan mesajı görebilir veya eklenmiş olan Sistem Mesajı\'nı kullanabilirsiniz.',
  com_endpoint_system_message: 'Sistem Mesajı',
  com_endpoint_message: 'Mesaj',
  com_endpoint_messages: 'Mesajlar',
  com_endpoint_message_not_appendable: 'Mesajınızı düzenleyin veya yeniden oluşturun.',
  com_endpoint_default_blank: 'varsayılan: boş',
  com_endpoint_default_false: 'varsayılan: yanlış',
  com_endpoint_default_creative: 'varsayılan: yaratıcı',
  com_endpoint_default_empty: 'varsayılan: boş',
  com_endpoint_default_with_num: 'varsayılan: {0}',
  com_endpoint_context: 'Bağlam',
  com_endpoint_tone_style: 'Ton Tarzı',
  com_endpoint_token_count: 'Token sayısı',
  com_endpoint_output: 'Çıktı',
  com_endpoint_context_tokens: 'Maksimum Bağlam Tokenleri',
  com_endpoint_context_info:
    'Bağlam için kullanılabilecek maksimum token sayısı. Bu, her istek için kaç token gönderileceğini kontrol etmek içindir. Belirtilmezse, bilinen modellerin bağlam boyutuna göre sistem varsayılanları kullanılacaktır. Daha yüksek değerler belirlemek hatalara ve/veya daha yüksek token maliyetine neden olabilir.',
  com_endpoint_google_temp:
    'Yüksek değerler = daha rastgele, düşük değerler = daha odaklı ve belirleyici. Bu parametre ile Olasılık Kütüphanesini değiştirmeyi öneririz (ikisini birden değiştirmemek).',
  com_endpoint_google_topp:
    'Olasılık Kütüphanesi, modelin çıktı için token seçme şeklini değiştirir. Tokenler, en olasılıktan (bkz. topK parametresi) en az olasıya kadar seçilir ve olasılıkları toplamı, top-p değerine eşit olana kadar devam eder.',
  com_endpoint_google_topk:
    'Top-k, modelin çıktı için token seçimini nasıl yaptığını değiştirir. 1 olan bir top-k, modelin kelime haznesindeki en olası tokenin seçildiği (açgözlü kod çözme olarak da adlandırılır) anlamına gelirken, 3 olan bir top-k, bir sonraki tokenin en olası üç token arasından (sıcaklık kullanılarak) seçileceği anlamına gelir.',
  com_endpoint_google_maxoutputtokens:
    'Yanıttaki maksimum token sayısı. Daha kısa yanıtlar için düşük bir değer, daha uzun yanıtlar için yüksek bir değer belirtin.',
  com_endpoint_google_custom_name_placeholder: 'Google için özel bir ad ayarlayın',
  com_endpoint_prompt_prefix_placeholder:
    'Özel talimatlar veya bağlam ayarlayın. Boşsa yok sayılır.',
  com_endpoint_instructions_assistants_placeholder:
    'Asistanın talimatlarını geçersiz kılar. Bu, davranışı tek tek çalışma bazında değiştirmek için yararlıdır.',
  com_endpoint_prompt_prefix_assistants_placeholder:
    'Asistanın ana talimatlarının üzerine ek talimatlar veya bağlam ekleyin. Boşsa yok sayılır.',
  com_endpoint_custom_name: 'Özel Ad',
  com_endpoint_prompt_prefix: 'Özel Talimatlar',
  com_endpoint_prompt_prefix_assistants: 'Ek Talimatlar',
  com_endpoint_instructions_assistants: 'Talimatları Geçersiz Kıl',
  com_endpoint_temperature: 'Sıcaklık',
  com_endpoint_default: 'varsayılan',
  com_endpoint_top_p: 'Top P',
  com_endpoint_top_k: 'Top K',
  com_endpoint_max_output_tokens: 'Maksimum Çıktı Tokenleri',
  com_endpoint_stop: 'Durdurma Sıraları',
  com_endpoint_stop_placeholder: 'Değerleri ayırmak için `Enter` tuşuna basın',
  com_endpoint_openai_max_tokens:
    'İsteğe bağlı `max_tokens` alanı, sohbet tamamlamalarında üretilebilecek maksimum token sayısını temsil eder. Giriş tokenlarının ve üretilen tokenların toplam uzunluğu, modellerin bağlam uzunluğu ile sınırlıdır. Bu sayının maksimum bağlam tokenlarını aşması durumunda hatalarla karşılaşabilirsiniz.',
  com_endpoint_openai_temp:
    'Yüksek değerler = daha rastgele, düşük değerler = daha odaklı ve belirleyici. Bu parametre ile Olasılık Kütüphanesi (top-p) değiştirmeyi öneririz (ikisini birden değiştirmemek).',
  com_endpoint_openai_max:
    'Üretilecek maksimum token sayısı. Giriş tokenlarının ve üretilen tokenların toplam uzunluğu modelin bağlam uzunluğu ile sınırlıdır.',
  com_endpoint_openai_topp:
    'Sıcaklıkla örneklemenin bir alternatifi olan, çekirdek örnekleme olarak adlandırılır, model top_p olasılık kütlesine sahip tokenların sonuçlarını dikkate alır. Yani 0.1, sadece top 10% olasılık kütlesine sahip tokenların dikkate alındığı anlamına gelir. Sıcaklık (temperature) ile değil bu parametre ile değiştirmenizi öneririz.',
  com_endpoint_openai_freq:
    ' -2.0 ile 2.0 arasında bir değer. Pozitif değerler, metinde daha önceki sıklığa bağlı olarak yeni tokenları cezalandırır, bu da modelin aynı hattı kelimesi kelimesine tekrar etme olasılığını azaltır.',
  com_endpoint_openai_pres:
    ' -2.0 ile 2.0 arasında bir değer. Pozitif değerler, metinde daha önceki varlıklarına dayalı olarak yeni tokenları cezalandırır, bu da modelin yeni konular hakkında konuşma olasılığını artırır.',
  com_endpoint_openai_resend:
    'Daha önce eklenmiş tüm görüntüleri yeniden gönderin. Not: Bu, token maliyetinizi önemli ölçüde artırabilir ve birden çok görüntü eklenmişse hatalarla karşılaşabilirsiniz.',
  com_endpoint_openai_resend_files:
    'Daha önce eklenmiş tüm dosyaları yeniden gönderin. Not: Bu, token maliyetinizi artıracaktır ve birden çok eklenmiş dosya ile hatalarla karşılaşabilirsiniz.',
  com_endpoint_openai_detail:
    'Görsel istekleri için çözünürlük. "Düşük" daha ucuz ve hızlıdır, "Yüksek" daha detaylı ve pahalıdır, "Otomatik" ise görüntü çözünürlüğüne göre ikisi arasında otomatik olarak bir seçim yapar.',
  com_endpoint_openai_stop: 'API\'nin ek tokenlar üretmeyi durduracağı en fazla 4 sıra.',
  com_endpoint_openai_custom_name_placeholder: 'AI için özel bir ad ayarlayın',
  com_endpoint_openai_prompt_prefix_placeholder:
    'Sistem Mesajına dahil edilecek özel talimatlar ayarlayın. Varsayılan: yok',
  com_endpoint_anthropic_temp:
    '0 ile 1 arasında değişir. Analitik / çoktan seçmeli sorular için 0\'a yakın, yaratıcı ve üretken görevler için 1\'e yakın bir sıcaklık kullanın. Bu parametre ile Olasılık Kütüphanesini değiştirmeyi öneririz (ikisini birden değiştirmemek).',
  com_endpoint_anthropic_topp:
    'Modelin çıktı için token seçim şeklini değiştirir. Tokenlar, en olasılıktan (bkz. topK parametresi) en az olasıya kadar seçilir ve olasılıkları toplamı, top-p değerine eşit olana kadar devam eder.',
  com_endpoint_anthropic_topk:
    'Top-k, modelin çıktı için token seçimini nasıl yaptığını değiştirir. 1 olan bir top-k, modelin kelime haznesindeki en olası tokenin seçildiği (açgözlü kod çözme olarak da adlandırılır) anlamına gelirken, 3 olan bir top-k, bir sonraki tokenin en olası üç token arasından (sıcaklık kullanılarak) seçileceği anlamına gelir.',
  com_endpoint_anthropic_maxoutputtokens:
    'Yanıttaki maksimum token sayısı. Daha kısa yanıtlar için düşük bir değer, daha uzun yanıtlar için yüksek bir değer belirtin.',
  com_endpoint_anthropic_custom_name_placeholder: 'Anthropic için özel bir ad ayarlayın',
  com_endpoint_frequency_penalty: 'Sıklık Cezası',
  com_endpoint_presence_penalty: 'Varlık Cezası',
  com_endpoint_plug_use_functions: 'Eklenti Fonksiyonlarını Kullanın',
  com_endpoint_plug_resend_files: 'Dosyaları Yeniden Gönderin',
  com_endpoint_plug_resend_images: 'Görüntüleri Yeniden Gönderin',
  com_endpoint_plug_image_detail: 'Görüntü Detayı',
  com_endpoint_plug_skip_completion: 'Tamamlamayı Atla',
  com_endpoint_disabled_with_tools: 'Araçlar ile devre dışı',
  com_endpoint_disabled_with_tools_placeholder: 'Araçlar ile Devre Dışı Bırakılmış',
  com_endpoint_plug_set_custom_instructions_for_gpt_placeholder:
    'Sistem Mesajına dahil edilecek özel talimatlar ayarlayın. Varsayılan: yok',
  com_endpoint_import: 'İthal et',
  com_endpoint_set_custom_name: 'Özelleştirmenizi adlandırın, böylece bu ayarlanabilir',
  com_endpoint_preset_delete_confirm: 'Bu hazır ayarı silmek istediğinizden emin misiniz?',
  com_endpoint_preset_clear_all_confirm:
    'Tüm hazır ayarlarınızı silmek istediğinizden emin misiniz?',
  com_endpoint_preset_import: 'Hazır Ayar İthal Edildi!',
  com_endpoint_preset_import_error:
    'Hazır ayarınızı ithal ederken bir hata oluştu. Lütfen tekrar deneyin.',
  com_endpoint_preset_save_error:
    'Hazır ayarınızı kaydederken bir hata oluştu. Lütfen tekrar deneyin.',
  com_endpoint_preset_delete_error:
    'Hazır ayarınızı silerken bir hata oluştu. Lütfen tekrar deneyin.',
  com_endpoint_preset_default_removed: 'Artık varsayılan hazır ayar değil.',
  com_endpoint_preset_default_item: 'Varsayılan:',
  com_endpoint_preset_default_none: 'Aktif varsayılan hazır ayar yok.',
  com_endpoint_preset_title: 'Hazır Ayar',
  com_ui_saved: 'Kaydedildi!',
  com_endpoint_preset_default: 'şu anda varsayılan hazır ayar.',
  com_endpoint_preset: 'hazır ayar',
  com_endpoint_presets: 'hazır ayarlar',
  com_endpoint_preset_selected: 'Hazır Ayar Aktif!',
  com_endpoint_preset_selected_title: 'Aktif!',
  com_endpoint_preset_name: 'Hazır Ayar Adı',
  com_endpoint_new_topic: 'Yeni Konu',
  com_endpoint: 'Uç Nokta',
  com_endpoint_hide: 'Gizle',
  com_endpoint_show: 'Göster',
  com_endpoint_examples: 'Hazır Ayarlar',
  com_endpoint_completion: 'Tamamlanma',
  com_endpoint_agent: 'Temsilci',
  com_endpoint_show_what_settings: '{0} Ayarlarını Göster',
  com_endpoint_export: 'Dışa Aktar',
  com_endpoint_export_share: 'Dışa Aktar / Paylaş',
  com_endpoint_assistant: 'Asistan',
  com_endpoint_use_active_assistant: 'Etkin Asistanı Kullan',
  com_endpoint_assistant_model: 'Asistan Modeli',
  com_endpoint_save_as_preset: 'Hazır Olarak Kaydet',
  com_endpoint_presets_clear_warning:
    'Tüm hazır ayarları temizlemek istediğinizden emin misiniz? Bu geri alınamaz.',
  com_endpoint_not_implemented: 'Uygulanmadı',
  com_endpoint_no_presets: 'Henüz hazır ayar yok, birini oluşturmak için ayar düğmesini kullanın',
  com_endpoint_not_available: 'Kullanılabilir uç nokta yok',
  com_endpoint_view_options: 'Seçenekleri Görüntüle',
  com_endpoint_save_convo_as_preset: 'Konuşmayı Hazır Olarak Kaydet',
  com_endpoint_my_preset: 'Benim Hazırım',
  com_endpoint_agent_model: 'Temsilci Modeli (Önerilen: GPT-3.5)',
  com_endpoint_completion_model: 'Tamamlanma Modeli (Önerilen: GPT-4)',
  com_endpoint_func_hover: 'Eklentilerin OpenAI Fonksiyonları olarak kullanılmasını etkinleştir',
  com_endpoint_skip_hover:
    'Tamamlama adımını atlamayı etkinleştir, bu adım nihai yanıtı ve oluşturulan adımları gözden geçirir',
  com_endpoint_config_key: 'API Anahtarını Ayarla',
  com_endpoint_assistant_placeholder: 'Lütfen sağ tarafta bir Asistan seçin',
  com_endpoint_config_placeholder: 'Sohbet etmek için Anahtarınızı Başlık menüsünde ayarlayın.',
  com_endpoint_config_key_for: 'API Anahtarını Ayarla',
  com_endpoint_config_key_name: 'Anahtar',
  com_endpoint_config_value: 'Değer girin',
  com_endpoint_config_key_name_placeholder: 'Öncelikle API anahtarını ayarlayın',
  com_endpoint_config_key_encryption: 'Anahtarınız şifrelenir ve şu tarihte silinir',
  com_endpoint_config_key_expiry: 'son kullanma tarihi',
  com_endpoint_config_click_here: 'Buraya Tıklayın',
  com_endpoint_config_google_service_key: 'Google Hizmet Hesabı Anahtarı',
  com_endpoint_config_google_cloud_platform: '(Google Cloud Platform\'dan)',
  com_endpoint_config_google_api_key: 'Google API Anahtarı',
  com_endpoint_config_google_gemini_api: '(Gemini API)',
  com_endpoint_config_google_api_info: 'Gemini için Yapay Zeka Dil API Anahtarınızı almak için,',
  com_endpoint_config_key_import_json_key: 'Hizmet Hesabı JSON Anahtarını İçe Aktar.',
  com_endpoint_config_key_import_json_key_success:
    'Hizmet Hesabı JSON Anahtarı Başarıyla İthal Edildi',
  com_endpoint_config_key_import_json_key_invalid:
    'Geçersiz Hizmet Hesabı JSON Anahtarı, doğru dosyayı ithal ettiniz mi?',
  com_endpoint_config_key_get_edge_key:
    'Bing için Erişim anahtarınızı almak için, şuraya giriş yapın',
  com_endpoint_config_key_get_edge_key_dev_tool:
    'Siteye giriş yaptıktan sonra geliştirici araçlarını veya uzantısını kullanarak _U çerezi içeriğini kopyalayın. Bu başarısız olursa, bu',
  com_endpoint_config_key_edge_instructions: 'talimatları takip edin',
  com_endpoint_config_key_edge_full_key_string: 'tam çerez dizelerini sağlamak için',
  com_endpoint_config_key_chatgpt:
    'ChatGPT \'Ücretsiz Sürüm\' için Erişim anahtarınızı almak için, şuraya giriş yapın',
  com_endpoint_config_key_chatgpt_then_visit: 'sonra burayı ziyaret edin',
  com_endpoint_config_key_chatgpt_copy_token: 'Erişim anahtarını kopyalayın.',
  com_endpoint_config_key_google_need_to: 'Şu işlemi yapmanız gerekiyor',
  com_endpoint_config_key_google_vertex_ai: 'Vertex AI\'ı Etkinleştirin',
  com_endpoint_config_key_google_vertex_api: 'Google Cloud\'da API\'yi etkinleştirin, ardından ',
  com_endpoint_config_key_google_service_account: 'Bir Hizmet Hesabı oluşturun',
  com_endpoint_config_key_google_vertex_api_role:
    '\'Oluştur ve Devam Et\' seçeneğine tıkladığınızdan emin olun ve en azından \'Vertex AI Kullanıcı\' rolünü verin. Son olarak, burada ithal etmek için bir JSON anahtarı oluşturun.',
  com_nav_welcome_assistant: 'Lütfen bir Asistan Seçin',
  com_nav_welcome_message: 'Bugün size nasıl yardımcı olabilirim?',
  com_nav_auto_scroll: 'Sohbet açıldığında otomatik olarak son mesaja kaydır',
  com_nav_hide_panel: 'Sağdaki paneli gizle',
  com_nav_modular_chat: 'Konuşmalar arasında uç noktaları değiştir',
  com_nav_latex_parsing: 'Mesajlarda LaTeX işleme (performansı etkileyebilir)',
  com_nav_text_to_speech: 'Metni Sese Çevir',
  com_nav_automatic_playback: 'Son Mesajı Otomatik Çal (yalnızca dış)',
  com_nav_speech_to_text: 'Sesi Metne Çevir',
  com_nav_profile_picture: 'Profil Resmi',
  com_nav_change_picture: 'Resmi değiştir',
  com_nav_plugin_store: 'Eklenti mağazası',
  com_nav_plugin_install: 'Yükle',
  com_nav_plugin_uninstall: 'Kaldır',
  com_ui_add: 'Ekle',
  com_nav_tool_remove: 'Kaldır',
  com_nav_tool_dialog: 'Asistan Araçları',
  com_nav_tool_dialog_description: 'Araç seçimlerinin kalıcı olması için asistan kaydedilmelidir.',
  com_show_agent_settings: 'Temsilci Ayarlarını Göster',
  com_show_completion_settings: 'Tamamlama Ayarlarını Göster',
  com_hide_examples: 'Örnekleri Gizle',
  com_show_examples: 'Örnekleri Göster',
  com_nav_plugin_search: 'Eklentileri Ara',
  com_nav_tool_search: 'Araçları Ara',
  com_nav_plugin_auth_error:
    'Bu eklenti ile kimlik doğrulama işlemi sırasında bir hata oluştu. Lütfen tekrar deneyin.',
  com_nav_export_filename: 'Dosya adı',
  com_nav_export_filename_placeholder: 'Dosya adını belirle',
  com_nav_export_type: 'Tür',
  com_nav_export_include_endpoint_options: 'Uç nokta seçeneklerini dahil et',
  com_nav_enabled: 'Etkin',
  com_nav_not_supported: 'Desteklenmiyor',
  com_nav_export_all_message_branches: 'Tüm mesaj dallarını dışa aktar',
  com_nav_export_recursive_or_sequential: 'Yinelenen mi, ardışık mı?',
  com_nav_export_recursive: 'Yinelenen',
  com_nav_export_conversation: 'Konuşmayı dışa aktar',
  com_nav_export: 'Dışa aktar',
  com_nav_shared_links: 'Paylaşılan bağlantılar',
  com_nav_shared_links_manage: 'Yönet',
  com_nav_shared_links_empty: 'Paylaşılan bağlantınız yok.',
  com_nav_shared_links_name: 'Ad',
  com_nav_shared_links_date_shared: 'Paylaşım tarihi',
  com_nav_source_chat: 'Kaynak sohbeti görüntüle',
  com_nav_my_files: 'Dosyalarım',
  com_nav_theme: 'Tema',
  com_nav_theme_system: 'Sistem',
  com_nav_theme_dark: 'Karanlık',
  com_nav_theme_light: 'Aydınlık',
  com_nav_enter_to_send: 'Mesajları göndermek için Enter tuşuna basın',
  com_nav_user_name_display: 'Mesajlarda kullanıcı adını görüntüle',
  com_nav_save_drafts: 'Taslakları yerel olarak kaydet',
  com_nav_show_code: 'Kod yorumlayıcı kullanırken her zaman kodu göster',
  com_nav_font_size: 'Yazı Boyutu',
  com_nav_clear_all_chats: 'Tüm sohbetleri temizle',
  com_nav_confirm_clear: 'Temizlemeyi Onayla',
  com_nav_close_sidebar: 'Yan paneli kapat',
  com_nav_open_sidebar: 'Yan paneli aç',
  com_nav_send_message: 'Mesajı gönder',
  com_nav_log_out: 'Çıkış yap',
  com_nav_user: 'KULLANICI',
  com_nav_archived_chats: 'Arşivlenmiş sohbetler',
  com_nav_archived_chats_manage: 'Yönet',
  com_nav_archived_chats_empty: 'Arşivlenmiş konuşmanız yok.',
  com_nav_archive_all_chats: 'Tüm sohbetleri arşivle',
  com_nav_archive_all: 'Hepsini arşivle',
  com_nav_archive_name: 'Ad',
  com_nav_archive_created_at: 'Oluşturulma Tarihi',
  com_nav_clear_conversation: 'Sohbetleri temizle',
  com_nav_clear_conversation_confirm_message:
    'Tüm konuşmaları temizlemek istediğinizden emin misiniz? Bu işlem geri alınamaz.',
  com_nav_help_faq: 'Yardım & SS',
  com_nav_settings: 'Ayarlar',
  com_nav_search_placeholder: 'Mesajları ara',
  com_nav_delete_account: 'Hesabı sil',
  com_nav_delete_account_confirm: 'Hesabı silmek istediğinizden emin misiniz?',
  com_nav_delete_account_button: 'Hesabımı kalıcı olarak sil',
  com_nav_delete_account_email_placeholder: 'Lütfen hesap e-postanızı girin',
  com_nav_delete_account_confirm_placeholder:
    'Devam etmek için, aşağıdaki giriş alanında "SİL" yazın',
  com_nav_delete_warning: 'UYARI: Bu işlem hesabınızı kalıcı olarak silecektir.',
  com_nav_delete_data_info: 'Tüm verileriniz silinecektir.',
  com_nav_conversation_mode: 'Konuşma Modu',
  com_nav_auto_send_text: 'Metni otomatik gönder (3 sn sonra)',
  com_nav_auto_transcribe_audio: 'Sesi otomatik olarak yazıya dök',
  com_nav_db_sensitivity: 'Desibel hassasiyeti',
  com_nav_playback_rate: 'Ses Çalma Hızı',
  com_nav_audio_play_error: 'Ses oynatma hatası: {0}',
  com_nav_audio_process_error: 'Ses işleme hatası: {0}',
  com_nav_long_audio_warning: 'Daha uzun metinlerin işlenmesi daha uzun sürecektir.',
  com_nav_engine: 'Motor',
  com_nav_browser: 'Tarayıcı',
  com_nav_external: 'Harici',
  com_nav_delete_cache_storage: 'Önbellek depolamayı sil',
  com_nav_enable_cache_tts: 'TTS önbelleğini etkinleştir',
  com_nav_voice_select: 'Ses Seçimi',
  com_nav_setting_general: 'General',
  com_nav_setting_beta: 'Beta features',
  com_nav_setting_data: 'Data controls',
  com_nav_setting_account: 'Account',
  com_nav_setting_speech: 'Speech',
  com_nav_language: 'Language',
  com_nav_lang_auto: 'Auto detect',
  com_nav_lang_english: 'English',
  com_nav_lang_chinese: '中文',
  com_nav_lang_german: 'Deutsch',
  com_nav_lang_spanish: 'Español',
  com_nav_lang_french: 'Français ',
  com_nav_lang_italian: 'Italiano',
  com_nav_lang_polish: 'Polski',
  com_nav_lang_brazilian_portuguese: 'Português Brasileiro',
  com_nav_lang_russian: 'Русский',
  com_nav_lang_japanese: '日本語',
  com_nav_lang_swedish: 'Svenska',
  com_nav_lang_korean: '한국어',
  com_nav_lang_vietnamese: 'Tiếng Việt',
  com_nav_lang_traditionalchinese: '繁體中文',
  com_nav_lang_arabic: 'العربية',
  com_nav_lang_turkish: 'Türkçe',
  com_nav_lang_dutch: 'Nederlands',
  com_nav_lang_indonesia: 'Indonesia',
  com_nav_lang_hebrew: 'עברית',
  com_nav_lang_finnish: 'Suomi',
};
